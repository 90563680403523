import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import GradientCard from '../shared/gradientCard'

export default function MarkingSection() {
  return (
    <Box>
      <Grid spacing={"50px"} container>
        <Grid id="Sales" item xs={12} md={3}>
          <GradientCard
            sx={{
              height: '100%',
              px: 4,
              pb: 4,
              display: 'flex',
              flexDirection: "column",
              rowGap: 2,
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "center", mt: "-50px" }}>
              <img width="80%" src="/images/gestion-proyectos1.png" alt="" />
            </Box>
            <Typography variant='h2'>Agenda</Typography>
            <Typography variant='label'>Gestione las citas de todos los miembros de su equipo, prepare reuniones desde ZOA para que el trabajo no se estanque.</Typography>
          </GradientCard>
        </Grid>
        <Grid id="Dashboard" item xs={12} md={9}>
          <GradientCard
            sx={{ height: '100%', p: 4 }}
          >
            <Grid sx={{ height: "100%" }} container spacing={2}>
              <Grid item xs={12} sm={7} sx={{ position: "relative" }}>
                <Box sx={{ display: "flex", justifyContent: "flex-start", zIndex: 10, position: "relative", pb: 2 }}>
                  <Box component={"img"} sx={{ width: "80%", mt: -4 }} src="/images/mail-concept.png" alt="" />
                </Box>
                <Box sx={{ position: "absolute", top: -40, right: { sm: 60, xs: 35 } }} component={"img"} src='/images/vector/vector9.svg' alt='' />
                <Box sx={{ position: "absolute", bottom: 0, left: { sm: "-10px", xs: "-15px" } }} component={"img"} src='/images/vector/vector18.svg' alt='' />
              </Grid>
              <Grid item xs={12} sm={5}>
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    rowGap: 2
                  }}
                >
                  <Typography sx={{ color: "var(--Monochromatic-06, #999)" }} variant='h4'>Promociónese</Typography>
                  <Typography variant='h2'>Mail Marketing</Typography>
                  <Typography variant='label'>Realice las campañas de marketing desde ZOA y ahorre en otros servicios que además no respetan su privacidad. Además controle las comunicaciones con sus clientes.</Typography>
                </Box>
              </Grid>
            </Grid>
          </GradientCard>
        </Grid>
        <Grid id="Agenda" item xs={12} md={9}>
          <GradientCard
            sx={{ height: '100%', p: 4 }}
          >
            <Grid container sx={{ height: "100%" }} spacing={2}>
              <Grid item xs={12} sm={7} sx={{ position: "relative" }}>
                <Box sx={{ display: "flex", justifyContent: "flex-start", pt: 2, pl: { sm: 4, xs: 0 }, pb: 4, position: "relative", zIndex: 10 }}>
                  <Box component={"img"} sx={{ width: "70%" }} src="/images/web-analytics-dashboard.png" alt="" />
                </Box>
                <Box component={"img"} sx={{ width: { xs: "30%", sm: "auto" }, position: "absolute", bottom: { sm: -25, xs: 10 }, left: { sm: 10, xs: -16 } }} src='/images/vector/vector29.svg' alt='' />
                <Box component={"img"} sx={{ width: { xs: "30%", sm: "auto" }, position: "absolute", right: { sm: 110, xs: 75 }, top: { sm: 10, xs: 10 } }} src='/images/vector/vector30.svg' alt='' />
                <Box component={"img"} sx={{ width: { xs: "30%", sm: "auto" }, position: "absolute", right: { sm: 110, xs: 75 }, bottom: { sm: 0, xs: 10 } }} src='/images/vector/vector31.svg' alt='' />
              </Grid>
              <Grid item xs={12} sm={5}>
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    rowGap: 2
                  }}
                >
                  <Typography sx={{ color: "var(--Monochromatic-06, #999)" }} variant='h4'>Gestión de productos</Typography>
                  <Typography variant='h2'>Ventas</Typography>
                  <Typography variant='label'>Controle absolutamente todas las ventas, envíos, preguntas de sus clientes y gestione las incidencias de una manera rápida y sencilla.</Typography>
                </Box>
              </Grid>
            </Grid>
          </GradientCard>
        </Grid>
        <Grid id="Mailings" item xs={12} md={3}>
          <GradientCard
            sx={{
              height: '100%',
              px: 4,
              pb: 4,
              display: 'flex',
              flexDirection: "column",
              rowGap: 2,
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "center", mt: "-30px" }}>
              <img width="80%" src="/images/DASHBOARDIMG1.png" alt="" />
            </Box>
            <Typography variant='h2'>Analítica</Typography>
            <Typography variant='label'>Tenga de un vistazo una instantánea de como se encuentran todos sus proyectos, citas y ventas para aumentar su productividad y la de sus equipos.</Typography>
          </GradientCard>
        </Grid>
      </Grid>
    </Box>
  )
}
