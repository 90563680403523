import React from 'react'
import {
    BrowserRouter,
    Routes,
    Route,
    Outlet,
    Navigate,
} from "react-router-dom";
// import { Navigate } from 'react-router-dom';
// import { useRecoilValue } from 'recoil';
// import { userState } from '../recoil/atoms/userState';
// import { authState } from '../recoil/atoms/authState';
import HomePage from '../pages';
import Login from '../pages/login';
import SignUp from '../pages/signup';
import Subscription from '../pages/subscription';
import Success from '../component/proof/success';
import Error from '../component/proof/error';
import Register from '../pages/register';
import ForgetPassword from '../pages/login/forgetPassword';
import SendToEmail from '../pages/login/sendToEmail';
import UserCheck from '../pages/login/userCheck';
// import { atom, useRecoilState } from 'recoil';

// const userIdState = atom({
//     key: 'uid',
//     default: localStorage.getItem('uid') || null,
// });

const AppRoutes = () => {
    // const [userId, setUserId] = useRecoilState(userIdState);

    // const userToken = useRecoilValue(userState)
    // const authToken = useRecoilValue(authState)

    // console.log("RecoilUserToken:=>", userToken)
    // console.log("RecoilAuthToken:=>", authToken)


    // const LoginRoutes = () => {
    //     if (!userId) {
    //         return (
    //             <Outlet />
    //         )
    //     }
    //     else {
    //         return (
    //             <Navigate to="/" replace />
    //         )
    //     }
    // }

    // const PrivateRoutes = () => {
    //     if (userId) {
    //         return (
    //             <Outlet />
    //         )
    //     }
    //     else {
    //         return (
    //             <Navigate to="/" replace />
    //         )
    //     }

    // }


    return (
        <BrowserRouter>
            <Routes>
                {/* <Route element={<LoginRoutes />}> */}
                <Route exact path="/login" element={<Login />} />
                <Route exact path="/user-check" element={<UserCheck />} />
                <Route exact path="/forget-password" element={<ForgetPassword />} />
                <Route exact path="/send-to-mail" element={<SendToEmail />} />
                <Route exact path="/sign-up" element={<SignUp />} />
                <Route exact path="/register" element={<Register />} />
                <Route exact path="/success" element={<Success />} />
                <Route exact path="/error" element={<Error />} />
                {/* </Route> */}
                <Route exact path="/" element={<HomePage />} />
                {/* <Route exact path="/payment-status" element={<PaymentStatus />} /> */}
                {/* <Route element={<PrivateRoutes />}> */}
                <Route exact path="/subscription" element={<Subscription />} />
                {/* </Route> */}
            </Routes>
        </BrowserRouter >
    );
};

export default AppRoutes;

{/* <Route exact path="/upload-data" element={<UploadData />} /> */ }