import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import GradientCard from '../shared/gradientCard'

export default function AboutSection() {
    return (
        <Box>
            <Grid columnSpacing={"100px"} rowGap={{ sm: "150px", xs: "50px" }} container>
                <Grid id="erp" sx={{ display: "flex", justifyContent: "center" }} item xs={12} sm={6}>
                    <Box sx={{ position: "relative", maxWidth: "600px", maxHeight: "800px" }}>
                        <GradientCard sx={{ px: { sm: 6, xs: 4 }, pb: 5, height: "100%" }}>
                            <Box sx={{
                                width: "100%",
                                height: { lg: "280px", sm: "200px", xs: "250px" },
                                zIndex: 10,
                                // marginLeft: { sm: "70px", xs: "20px" },
                                position: "relative",
                            }}>
                                <Box component={"img"} style={{ float: "inline-end", zIndex: 2, position: "relative" }} src="/images/about-image1.png" sx={{ width: "85%", objectFit: "contain", }} alt='' />
                                <Box component={"img"} src="/images/vector/vector3.svg"
                                    sx={{
                                        width: { sm: "50%", xs: "60%" },
                                        position: "absolute",
                                        top: { sm: -20, xs: -30 },
                                        left: { sm: 10, xs: 15 },
                                        zIndex: 1,
                                    }} alt='' />
                            </Box>
                            <Box
                                sx={{
                                    width: "100%",
                                    height: "calc(100% - 280px)",
                                    display: "flex",
                                    flexDirection: "column",
                                    rowGap: 2,
                                }}
                            >
                                <Typography sx={{ color: "var(--Monochromatic-06, #999)" }} variant='h4'>¡Descubre el ERP que cambiará tu vida!</Typography>
                                <Typography variant='h2'>El ERP fácil de usar, que mejora la productividad y ahorra costes</Typography>
                                <Typography variant='label' sx={{ letterSpacing: "-0.8px" }}>Nuestro ERP fácil de usar está diseñado pensando en ti y en tus necesidades. Olvídate de largos manuales y horas de entrenamiento. Con nuestra plataforma intuitiva, podrás empezar a utilizarla de inmediato, sin problemas ni complicaciones.</Typography>
                            </Box>
                        </GradientCard>
                    </Box>
                </Grid>
                <Grid id="crm" sx={{ display: "flex", justifyContent: "center" }} item xs={12} sm={6}>
                    <Box sx={{ position: "relative", maxWidth: "600px", maxHeight: "800px" }}>
                        <GradientCard sx={{ px: { sm: 6, xs: 4 }, pb: 2, height: "100%" }}>
                            <Box sx={{
                                width: "100%",
                                zIndex: 10,
                                position: "relative",
                                height: { lg: "280px", sm: "200px", xs: "250px" },
                                display: "flex",
                                justifyContent: "center",
                            }}>
                                <Box component={"img"} sx={{ objectFit: "contain", marginTop: { sm: "-50px", xs: "-10px" }, width: "85%", position: "relative", zIndex: 2 }} src="/images/about-image2.png" alt='' />
                                <Box component={"img"}
                                    sx={{
                                        width: { sm: "40%", xs: "40%" },
                                        position: "absolute",
                                        top: { lg: 125, sm: 80, xs: 125 },
                                        left: { lg: -20, sm: -20, xs: -15 },
                                        zIndex: 1,
                                    }}
                                    src="/images/vector/vector8.svg" alt='' />
                                <Box component={"img"}
                                    sx={{
                                        width: { sm: "60%", xs: "55%" },
                                        position: "absolute",
                                        top: { lg: 10, sm: -10, xs: 70 },
                                        right: { lg: -30, sm: -25, xs: -10 },
                                        zIndex: 1,
                                    }}
                                    src="/images/vector/vector25.svg" alt='' />
                            </Box>
                            <Box
                                sx={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    rowGap: 2,
                                }}
                            >
                                <Typography sx={{ color: "var(--Monochromatic-06, #999)" }} variant='h4'>CRM que vale la pena</Typography>
                                <Typography variant='h2'>¡Aprovecha el CRM y establece una conexión sólida con tus clientes!</Typography>
                                <Typography variant='label' sx={{ letterSpacing: "-0.8px" }}>¿Estás buscando una forma efectiva de mejorar tus relaciones con los clientes? ¡No busques más! Un CRM es la herramienta perfecta para ayudarte a lograrlo. Con un CRM, podrás gestionar de manera eficiente toda la información relacionada con tus clientes, desde sus datos de contacto hasta sus preferencias y necesidades. ¡Imagina la ventaja que tendrás al conocer a tus clientes a fondo!</Typography>
                            </Box>
                        </GradientCard>
                    </Box>
                </Grid>
                <Grid id="Projects" sx={{ display: "flex", justifyContent: "center" }} item xs={12} sm={6}>
                    <Box sx={{ position: "relative", maxWidth: "600px", maxHeight: "800px" }}>
                        <GradientCard sx={{ px: { sm: 6, xs: 4 }, pb: 2, height: "100%" }}>
                            <Box sx={{
                                width: "100%",
                                height: { lg: "280px", sm: "200px", xs: "250px" },
                                zIndex: 10,
                                position: "relative",
                            }}>
                                <Box component={"img"} sx={{ float: "inline-end", marginTop: { sm: "-10px", xs: "35px" }, marginBottom: { sm: "0px", xs: "70px" }, width: "85%", position: "relative", zIndex: 2 }} src="/images/about-image3.png" alt='' />
                                <Box component={"img"}
                                    sx={{
                                        width: { sm: "75%", xs: "85%" },
                                        position: "absolute",
                                        top: { sm: -50, xs: -10 },
                                        left: { sm: -15, xs: -10 },
                                        zIndex: 1,
                                    }} src="/images/vector/vector26.svg" alt='' />
                            </Box>
                            <Box
                                sx={{
                                    width: "100%",
                                    height: "calc(100% - 280px)",
                                    display: "flex",
                                    flexDirection: "column",
                                    rowGap: 2,
                                }}
                            >
                                <Typography sx={{ color: "var(--Monochromatic-06, #999)" }} variant='h4'>Colaboración en tiempo real</Typography>
                                <Typography variant='h2'>Mantén a tus equipos siempre conectados, sin importar el lugar</Typography>
                                <Typography variant='label' sx={{ letterSpacing: "-0.8px" }}>¡No dejes que la distancia sea un obstáculo para la colaboración! Con nuestra solución de conectividad, podrás mantener a tus equipos unidos sin importar dónde se encuentren. Ya sea que estén trabajando desde la oficina, desde casa o incluso desde diferentes partes del mundo, podrán comunicarse y colaborar de manera efectiva.</Typography>
                            </Box>
                        </GradientCard>
                    </Box>
                </Grid>
                <Grid id="Communication" sx={{ display: "flex", justifyContent: "center" }} item xs={12} sm={6}>
                    <Box sx={{ position: "relative", maxWidth: "600px", maxHeight: "800px" }}>
                        <GradientCard sx={{ px: { sm: 6, xs: 4 }, pb: 2, height: "100%" }}>
                            <Box sx={{
                                width: "100%",
                                zIndex: 10,
                                position: "relative",
                                height: { lg: "280px", sm: "200px", xs: "250px" },
                                display: "flex",
                                justifyContent: "center",
                            }}>
                                <Box component={"img"} src="/images/about-image4.png" sx={{ width: { lg: "85%", xs: "90%" }, objectFit: "contain", position: "relative", zIndex: 2, marginTop: { lg: "-50px", sm: "-25px", xs: "-20px" } }} alt='' />
                                <Box component={"img"}
                                    sx={{
                                        width: { sm: "50%", xs: "50%" },
                                        position: "absolute",
                                        top: { lg: 65, sm: 30, xs: 70 },
                                        left: { lg: -30, sm: -30, xs: -25 },
                                        zIndex: 1,
                                    }}
                                    src="/images/vector/vector27.svg" alt='' />
                                <Box component={"img"}
                                    sx={{
                                        width: { sm: "45%", xs: "35%" },
                                        position: "absolute",
                                        top: { lg: -70, sm: -50, xs: -45 },
                                        right: { lg: -10, sm: -10, xs: 20 },
                                        zIndex: 1,
                                    }}
                                    src="/images/vector/vector28.svg" alt='' />
                            </Box>
                            <Box
                                sx={{
                                    width: "100%",
                                    height: "calc(100% - 280px)",
                                    display: "flex",
                                    flexDirection: "column",
                                    rowGap: 2,
                                }}
                            >
                                <Typography sx={{ color: "var(--Monochromatic-06, #999)" }} variant='h4'>Un gestor de proyectos  eficiente</Typography>
                                <Typography variant='h2'>Gestiona los proyectos de manera sencilla, fácil e intuitiva </Typography>
                                <Typography variant='label' sx={{ letterSpacing: "-0.8px" }}>En lugar de perder horas valiosas en la gestión de proyectos complicados, te ofrecemos una solución simple y eficiente. Nuestra plataforma intuitiva te permitirá administrar tus proyectos de manera fácil y sin complicaciones. Ahorrarás tiempo valioso que podrás dedicar a otras tareas importantes.</Typography>
                            </Box>
                        </GradientCard>
                    </Box>
                </Grid>
            </Grid>
        </Box >
    )
}
