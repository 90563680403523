import { Box, Typography } from '@mui/material'
import React from 'react'
import BasicThemeButton from '../shared/button'
import GradientCard from '../shared/gradientCard'
import { useNavigate } from 'react-router-dom'
import { atom, useRecoilState } from 'recoil'
const userIdState = atom({
    key: 'uid',
    default: localStorage.getItem('uid') || null,
});

export default function HeroSection() {
    const navigate = useNavigate()
    const [userId, setUserId] = useRecoilState(userIdState);


    return (
        <>
            <GradientCard>
                <Box sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    width: { sm: "70%", xs: "100%" },
                    mx: "auto",
                    px: { sm: 2, xs: 2 },
                    pt: 2
                }}>
                    <Typography
                        sx={{
                            background: "var(--Gradiente-normal, linear-gradient(90deg, #05C7F2 0%, #0CF22F 100%))",
                            backgroundClip: "text",
                            color: "transparent",
                            width: "100%",
                            textAlign: "center",
                        }}
                        variant='h1'>ZOA</Typography>
                    <Typography variant='h1'>Conecta todo</Typography>
                    <Typography variant='h1'>Conecta a todos</Typography>
                    <Typography sx={{ width: { sm: "480px", xs: "auto" }, textAlign: "center", mt: 2, mb: 3 }} variant='label'>Proyectos, clientes, conversaciones, contactos, ideas y mucho más, todo lo que hay que tener en cuenta en tu negocio en un sitio para que no se te escape nada.</Typography>
                    <BasicThemeButton
                        onClick={() => navigate(userId ? "/subscription" : "/register")}
                        title={"Pruébalo ahora"}
                        icon={<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                            <path d="M9.90041 32.528C9.90041 32.528 6.49441 37.608 8.48641 39.598C10.4764 41.59 15.5564 38.184 15.5564 38.184" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M41.012 7.07198C41.2509 7.31091 41.4253 7.60643 41.519 7.93098C41.6127 8.25553 41.6227 8.59853 41.548 8.92798L40.914 11.732C40.1629 15.0459 38.489 18.0795 36.086 20.482L26.798 29.77C28.852 32.314 29.518 34.73 28.998 37.034C28.456 39.434 26.678 41.206 25.574 42.308L25.454 42.428C25.2391 42.6426 24.978 42.8055 24.6907 42.9042C24.4034 43.0029 24.0974 43.0349 23.7959 42.9977C23.4944 42.9605 23.2054 42.8551 22.9507 42.6895C22.696 42.5239 22.4824 42.3025 22.326 42.042L18.34 35.392C18.2561 35.2522 18.1553 35.1233 18.04 35.008L12.9 29.872L11.38 28.96L18.298 21.274C18.3027 21.2774 18.3073 21.2807 18.312 21.284L27.6 12C30.0031 9.59669 33.0374 7.92281 36.352 7.17198L39.156 6.53598C39.4857 6.46156 39.8288 6.47187 40.1533 6.56595C40.4779 6.66003 40.7733 6.83282 41.012 7.07198ZM16.644 20.124C14.706 18.994 12.844 18.68 11.05 19.084C8.65003 19.628 6.87803 21.404 5.77603 22.51L5.65603 22.628C5.44138 22.8429 5.27852 23.104 5.17981 23.3913C5.08111 23.6786 5.04914 23.9846 5.08635 24.2861C5.12355 24.5876 5.22895 24.8767 5.39454 25.1313C5.56013 25.386 5.78157 25.5996 6.04203 25.756L9.63403 27.912L16.644 20.124Z" fill="white" />
                        </svg>}
                        textVariant={"h4"}
                        style={{
                            padding: { sm: "16px 24px", xs: "8px 24px" },
                        }
                        }
                    />
                    <Box sx={{
                        display: { xs: "block", sm: "block" },
                        width: "100%",
                        position: "relative",
                        backgroundImage: "url(/images/zoa-inicio.png)",
                        height: { xs: "100px", md: "36vh" },
                        backgroundRepeat: "no-repeat",
                        backgroundPositionX: "center",
                        backgroundSize: "50%",
                        backgroundPositionY: "bottom",
                    }}>
                        <Box
                            sx={{
                                position: "absolute",
                                width: "100%",
                                height: "100%",
                                backgroundImage: "url(/images/zoa-reunion-2.png)",
                                backgroundRepeat: "no-repeat",
                                backgroundPositionX: "left",
                                backgroundPositionY: "bottom",
                                backgroundSize: "30%",
                            }}
                        />
                        <Box
                            sx={{
                                position: "absolute",
                                width: "100%",
                                height: "100%",
                                backgroundImage: "url(/images/zoa-conversaciones.png)",
                                backgroundRepeat: "no-repeat",
                                backgroundPositionX: "right",
                                backgroundPositionY: "bottom",
                                backgroundSize: "30%",
                            }}
                        />
                    </Box>
                    <Box sx={{ display: { xs: "none", sm: "block" } }}>
                        <img style={{
                            position: "absolute",
                            // top: 50,
                            left: -25,
                            bottom: 135,
                            marginBlock: "auto",
                        }} src="/images/hero1.png" alt='' width={"35%"} />

                        <img
                            style={{
                                position: "absolute",
                                // top: 130,
                                right: -25,
                                bottom: 75,
                                marginBlock: "auto",
                            }}
                            src="/images/hero2.png" width={"40%"} alt='' />
                    </Box>
                </Box>
            </GradientCard>
        </>
    )
}
