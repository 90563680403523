import { Box, Divider, Fab, Grid, IconButton, InputAdornment, MenuItem, Table, TableCell, TableContainer, TableRow, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import BasicThemeButton from '../shared/button'
import CancelSharpIcon from '@mui/icons-material/CancelSharp';
import AddIcon from '@mui/icons-material/Add';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import styled from '@emotion/styled';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { AddMoreUser, DeleteUser, getUserPlan, updateData } from '../../controller/userController';
import PageLoader from '../shared/loader';
import AddUserModal from './addUserModal';
import { toast } from 'react-toastify';
import DeleteModal from '../shared/deleteModal';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    borderBottom: "none",
    fontSize: "16px",
    padding: 4
}));


export default function ManageUsers(userId) {
    const [planData, setPlanData] = useState()
    const [filteredPlanData, setFilteredPlanData] = useState()
    console.log("filteredPlanData: ", filteredPlanData);
    const [loading, setLoading] = useState(false)
    const [modalOpen, setModalOpen] = useState(false);
    const [handleDeleteOpen, setHandleDeleteOpen] = useState(false);
    const [companyId, setCompanyId] = useState("")
    const [updated, setUpdated] = useState(false)
    const [searchActive, setSearchActive] = useState(false)
    const [searchText, setSearchText] = useState("")
    const [subscriptionId, setSubscriptionId] = useState("")
    const [selectedUserId, setSelectedUserId] = useState("")
    const uid = localStorage.getItem("uid")
    const [userData, setUserData] = useState({
        userName: "",
        userSurname: "",
        rol: "",
        age: "",
        mail: "",
    })
    console.log("userData: ", userData);

    const AddUser = async () => {
        setLoading(true)
        const data = { ...userData, company_id: companyId, activeSubscriptionId: subscriptionId }
        if (Object.values(userData).every(value => value !== "")) {
            const result = await AddMoreUser(data)
            console.log("result: ", result);
            if (result.status === "success") {
                setLoading(false)
                handleClose()
                getPlan()
                setUserData({
                    userName: "",
                    userSurname: "",
                    rol: "",
                    age: "",
                    mail: "",
                })
            } else {
                setLoading(false)
                toast.error(result.data.msg)
            }
        } else {
            setLoading(false)
        }
    }

    const updateUser = async () => {
        const data = {
            user: { uid: userData.uid, data: { ...userData, company_id: companyId, activeSubscriptionId: subscriptionId } }
        }
        console.log("data: ", data);
        if (Object.values(userData).every(value => value !== "")) {
            const result = await updateData(data)
            console.log("result: ", result);
            if (result.status === "success") {
                setLoading(false)
                handleClose()
                getPlan()
                setUserData({
                    userName: "",
                    userSurname: "",
                    rol: "",
                    age: "",
                    mail: "",
                })
            } else {
                setLoading(false)
                toast.error(result.data.msg)
            }
        } else {
            setLoading(false)
        }
    }

    const handleClickOpen = () => {
        setModalOpen(true);
        setUpdated(false)
        setUserData({
            userName: "",
            userSurname: "",
            rol: "",
            age: "",
            mail: "",
        })
    };

    const handleEditOpen = (val) => {
        setUpdated(true)
        setUserData(val);
        setModalOpen(true);
    };

    const handleClose = () => {
        setModalOpen(false);
    };

    const handleDeleteOpenModel = (val) => {
        setHandleDeleteOpen(true);
        setSelectedUserId(val)
    };

    const handleDeleteClose = () => {
        setHandleDeleteOpen(false);
        setSelectedUserId("")
    };

    const handleDeleteUser = async () => {
        setLoading(true)
        const data = { uid: selectedUserId }
        const result = await DeleteUser(data);
        if (result.status === "success") {
            setLoading(false)
            handleDeleteClose()
            getPlan()
        } else {
            setLoading(false)
            toast.error(result.data.msg)
        }
    }

    const getPlan = async () => {
        setLoading(true)
        const data = {
            uid: userId.userId
        }
        const result = await getUserPlan(data)
        if (result.status === "success") {
            const companyUsers = result.data.companyUsers
            const newData = filterAndSortData(companyUsers)
            console.log("newData: ", newData);
            setPlanData(newData)
            setFilteredPlanData(newData)
            setCompanyId(result.data.user.company_id)
            setSubscriptionId(result.data.user.activeSubscriptionId)
            setLoading(false)
        }
    }

    const handleChangeSearchText = (value) => {
        setSearchText(value)
    }

    useEffect(() => {
        if (searchActive && searchText) {
            const filteredUsers = planData.filter(user => {
                const query = searchText.toLowerCase();
                return user.userName.toLowerCase().includes(query) ||
                    user.mail.toLowerCase().includes(query);
            }
                // user.userName.toLowerCase().includes(searchText.toLowerCase())
            );
            setFilteredPlanData(filteredUsers);
        } else {
            setFilteredPlanData(planData)
        }
    }, [searchText])

    const filterAndSortData = (data) => {
        const matchingData = data.filter(item => item.uid === uid);
        const remainingData = data.filter(item => item.uid !== uid);
        const sortedData = [...matchingData, ...remainingData];
        return sortedData;
    };

    useEffect(() => {
        getPlan()
    }, [])

    return (
        <>
            <Box sx={{ minHeight: "100%", p: 4 }}>
                <Box sx={{ width: { sm: "100%", xs: "100%" }, mb: 5 }}>
                    <Typography sx={{ fontSize: "26px", textAlign: "center", letterSpacing: "-1.6px", fontWeight: 700 }} variant='h3'>Gestione a los usuarios de su ZOA.</Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center", position: "relative", alignItems: "center", flexDirection: "column", height: "100%" }}>
                    <Box sx={{
                        background: "#fff",
                        width: "100%",
                        height: "48vh",
                        borderRadius: "24px",
                        overflow: "hidden",
                        display: "flex",
                        flexDirection: "column",
                        pl: 2,
                        pr: { sm: 10, xs: 2 },
                        py: 3
                    }}>
                        <Box>
                            <Grid container>
                                {/* <Grid item xs={4}>
                                <Typography component={Box} sx={{ fontWeight: 600 }} variant='label'>Quedan X</Typography>
                                <Typography component={Box} sx={{ fontWeight: 600 }} variant='label'>usuarios libres</Typography>
                            </Grid> */}
                                <Grid sx={{ display: "flex", justifyContent: "flex-end", columnGap: 4 }} item xs={12}>
                                    <Fab onClick={handleClickOpen} sx={{
                                        backgroundColor: "#2D5BFF", color: "#fff", height: "40px", boxShadow: "none", textTransform: "capitalize", "&:hover": { backgroundColor: "#2D5BFF" },
                                    }} size='small' variant="extended">
                                        <Box sx={{ display: "flex", alignItems: "center", columnGap: 0.5, px: 1 }}>
                                            <AddIcon />
                                            <Typography sx={{ letterSpacing: "-0.5px" }}>
                                                Añadir usuario
                                            </Typography>
                                        </Box>
                                    </Fab>
                                    {searchActive ?
                                        <TextField
                                            size='small'
                                            value={searchText}
                                            onChange={(e) => handleChangeSearchText(e.target.value)}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="start">
                                                    <IconButton onClick={() => {
                                                        setSearchActive(false)
                                                        setSearchText("")
                                                    }}>
                                                        < CancelSharpIcon />
                                                    </IconButton>
                                                </InputAdornment>,
                                            }}
                                        />
                                        :
                                        <Fab onClick={() => setSearchActive(true)} size='small' sx={{ backgroundColor: "transparent", height: "40px", boxShadow: "none", border: "1px solid", textTransform: "capitalize", "&:hover": { backgroundColor: "transparent" } }} variant="extended">
                                            <Box sx={{ display: "flex", alignItems: "center", columnGap: 0.5, px: 1 }}>
                                                <ManageAccountsIcon sx={{ mr: 1 }} />
                                                <Typography sx={{ letterSpacing: "-0.5px" }}>
                                                    Buscar usuario
                                                </Typography>
                                            </Box>
                                        </Fab>
                                    }
                                </Grid>
                            </Grid>
                        </Box>
                        <Divider sx={{ marginBlock: 1 }} />
                        {!loading &&
                            <Box sx={{ height: "100%", overflowY: "auto" }}>
                                <TableContainer>
                                    <Table>
                                        {filteredPlanData?.map((data, index) =>
                                            <TableRow sx={{ letterSpacing: "-0.5px" }} key={index}>
                                                <StyledTableCell component="th" scope="row">
                                                    <AccountCircleOutlinedIcon />
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    {`${data.userName} ${data.userSurname}`}
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    {data.mail}
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    {data.rol}
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    <IconButton onClick={() => handleEditOpen(data)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                                            <path d="M7 7.5H6C5.46957 7.5 4.96086 7.71071 4.58579 8.08579C4.21071 8.46086 4 8.96957 4 9.5V18.5C4 19.0304 4.21071 19.5391 4.58579 19.9142C4.96086 20.2893 5.46957 20.5 6 20.5H15C15.5304 20.5 16.0391 20.2893 16.4142 19.9142C16.7893 19.5391 17 19.0304 17 18.5V17.5" stroke="#444444" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                            <path d="M16 5.50011L19 8.50011M20.385 7.08511C20.7788 6.69126 21.0001 6.15709 21.0001 5.60011C21.0001 5.04312 20.7788 4.50895 20.385 4.11511C19.9912 3.72126 19.457 3.5 18.9 3.5C18.343 3.5 17.8088 3.72126 17.415 4.11511L9 12.5001V15.5001H12L20.385 7.08511Z" stroke="#444444" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                        </svg>
                                                    </IconButton>
                                                </StyledTableCell>
                                                {data.uid !== uid
                                                    &&
                                                    <StyledTableCell>
                                                        <IconButton onClick={() => handleDeleteOpenModel(data.uid)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                                                <path d="M7 21.5C6.45 21.5 5.979 21.304 5.587 20.912C5.195 20.52 4.99933 20.0493 5 19.5V6.5H4V4.5H9V3.5H15V4.5H20V6.5H19V19.5C19 20.05 18.804 20.521 18.412 20.913C18.02 21.305 17.5493 21.5007 17 21.5H7ZM9 17.5H11V8.5H9V17.5ZM13 17.5H15V8.5H13V17.5Z" fill="#444444" />
                                                            </svg>
                                                        </IconButton>
                                                    </StyledTableCell>
                                                }
                                            </TableRow>
                                        )}
                                    </Table>
                                </TableContainer>
                            </Box>
                        }
                    </Box>
                    {/* <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%", mt: 2 }}>
                    <BasicThemeButton
                        title={"Guardar cambios"}
                        textVariant="h4"
                        icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                            <path d="M3.464 21.035C4.93 22.5 7.286 22.5 12 22.5C16.714 22.5 19.071 22.5 20.535 21.035C22 19.572 22 17.214 22 12.5C22 12.159 22 11.988 21.985 11.814C21.911 10.9963 21.5897 10.2206 21.064 9.59C20.9102 9.41529 20.749 9.24714 20.581 9.086L15.414 3.919C15.2527 3.75112 15.0846 3.58999 14.91 3.436C14.2793 2.91067 13.5035 2.58978 12.686 2.516C12.512 2.5 12.34 2.5 12 2.5C7.286 2.5 4.929 2.5 3.464 3.964C2 5.43 2 7.786 2 12.5C2 17.214 2 19.571 3.464 21.035Z" stroke="white" stroke-width="1.5" />
                            <path d="M17 22.5V21.5C17 19.614 17 18.672 16.414 18.086C15.828 17.5 14.886 17.5 13 17.5H11C9.114 17.5 8.172 17.5 7.586 18.086C7 18.672 7 19.614 7 21.5V22.5" stroke="white" stroke-width="1.5" />
                            <path d="M7 8.5H13" stroke="white" stroke-width="1.5" stroke-linecap="round" />
                        </svg>}
                        style={{
                            padding: "16px 24px",
                            textTransform: "capitalize",
                        }}
                    />
                </Box> */}

                </Box>
            </Box>
            <AddUserModal
                modalOpen={modalOpen}
                handleClose={handleClose}
                userData={userData}
                setUserData={setUserData}
                AddUser={AddUser}
                updateUser={updateUser}
                updated={updated}
            />
            <DeleteModal open={handleDeleteOpen} DeleteUser={handleDeleteUser} handleClose={handleDeleteClose} />
            {loading && <PageLoader />}
        </>
    )
}
