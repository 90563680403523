import styled from '@emotion/styled';
import { Switch } from '@mui/material';
import React from 'react'

const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 40,
    height: 25,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '& + .MuiSwitch-track': {
            opacity: 1,
            backgroundColor: '#1890ff',
        },
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: '#04d94f',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 20,
        height: 20,
        borderRadius: "50%",
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 25,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}));

export default function ThemeSwitch({ checked, onChange }) {
    return (
        <AntSwitch
            defaultChecked
            checked={checked}
            onChange={onChange}
            inputProps={{ 'aria-label': 'ant design' }}
        />
    )
}
