import { Box, Button, Grid, Typography } from '@mui/material'
import React from 'react'
import { useScroll } from '../../context/scrollContext';

const info = [
  {
    image: "/images/erp5.png",
    title: "ERP",
    details: "El ERP más fácil de usar, que te ayudará a mejorar la productividad y ahorrar costes.",
    path: "erp"
  },
  {
    image: "/images/crm.png",
    title: "CRM",
    details: "¡Aprovecha al máximo un CRM y establece una conexión sólida con tus clientes!",
    path: "crm"
  },
  {
    image: "/images/proyectos.png",
    title: "Proyectos",
    details: "Gestiona todos los pasos de tus proyectos y mantén a tu equipo informado de todos los cambios.",
    path: "Projects"
  },
  {
    image: "/images/Comunicación.png",
    title: "Comunicación",
    details: "Gestiona todas las comunicaciones entre los miembros de la organización y los clientes.",
    path: "Communication"
  },
  {
    image: "/images/Ventas.png",
    title: "Ventas",
    details: "Gestiona las ventas de tu negocio desde ZOa para tener controlados todos tus pedidos y envíos.",
    path: "Sales"
  },
  {
    image: "/images/Dashboard.png",
    title: "Dashboard",
    details: "Obtén de un vistazo toda la información de lo que haces en ZOA para tener el control absoluto.",
    path: "Dashboard"
  },
  {
    image: "/images/Agenda.png",
    title: "Agenda",
    details: "Organiza tus equipos de trabajo, prepara las reuniones con clientes desde un solo lugar.",
    path: "Agenda"
  },
  {
    image: "/images/Mailings.png",
    title: "Mailings",
    details: "Avisa a tus clientes de tus novedades, a tus empleados de los cambios y avisos de manera sencilla.",
    path: "Mailings"
  },
]

export default function DetailSection() {
  const { scrollTo } = useScroll();



  return (
    <Box>
      <Box sx={{ textAlign: "center" }}>
        <Typography sx={{ color: "var(--Monochromatic-06, #999)", }} variant='h4'>¿Cómo podemos ayudarte?</Typography>
        <Typography sx={{ mt: 0.5 }} variant='h2'>ZOA una aplicación para hacer crecer tu negocio</Typography>
      </Box>
      <Box sx={{ px: { xs: 1, sm: 4 } }}>
        <Grid container columnSpacing={{ sm: "5%", xs: "20px" }}>
          {info.map((item, index) =>
            <Grid key={index} sx={{ pt: 2 }} item xs={6} md={3}>
              <Box
                sx={{
                  display: "flex",
                  borderRadius: "24px",
                  border: "2px solid",
                  borderColor: "#05C7F2",
                  background: "var(--Monochromatic-00, #FFF)",
                  flexDirection: "column",
                  alignItems: "center",
                  rowGap: 0.5,
                  height: "100%",
                  py: 1.5,
                  px: 3,
                  transition: "border-color 0.3s ease",
                  "& .MuiButtonBase-root": {
                    transition: "background 0.3s ease",
                    background: "#7FE47E",
                  },
                  "&:hover": {
                    borderColor: "#7fe47e",
                    "& .MuiButtonBase-root": {
                      background: "#05C7F2",
                    },
                  },
                }}
              >
                <Box sx={{ width: "56px", height: "56px" }}>
                  <img src={item.image} alt="" width={"100%"} />
                </Box>
                <Typography sx={{ color: "#666" }} variant='h4'>{item.title}</Typography>
                <Box sx={{ height: "100%", display: "flex", rowGap: 2, flexDirection: "column", justifyContent: "space-between", alignItems: "center" }}>
                  <Typography variant='label'>{item.details}</Typography>
                  <Button
                    onClick={() => scrollTo(item.path)}
                    sx={{
                      borderRadius: "24px",
                      width: "85%",
                      textTransform: "math-auto",
                    }}
                    variant='contained'
                  >Ver más</Button>
                </Box>
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  )
}
