import React, { useEffect, useState } from 'react'
import { Box, Button, Divider, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import ThemeSwitch from '../shared/switch'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import BasicThemeButton from '../shared/button';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';
import { getPriceData } from '../../controller/priceController';
import PageLoader from '../shared/loader';

const userAddIcon = <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
    <path d="M10 10C12.2091 10 14 8.20914 14 6C14 3.79086 12.2091 2 10 2C7.79086 2 6 3.79086 6 6C6 8.20914 7.79086 10 10 10Z" stroke="black" stroke-width="1.5" />
    <path d="M21 10H19M19 10H17M19 10V8M19 10V12M17.998 18C18 17.836 18 17.669 18 17.5C18 15.015 14.418 13 10 13C5.582 13 2 15.015 2 17.5C2 19.985 2 22 10 22C12.231 22 13.84 21.843 15 21.563" stroke="black" stroke-width="1.5" stroke-linecap="round" />
</svg>

export default function PlanSelect({ handlePlanSelect, handlePrev }) {
    const [quantity, setQuantity] = useState(1)
    const [loading, setLoading] = useState(false)
    const [show, setShow] = useState(true)
    const [checked, setChecked] = useState(true);
    const [price, setPrice] = useState("");
    const [plan, setPlan] = useState();

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    const getPlanPrice = async () => {
        setShow(false);
        setLoading(true)
        const result = await getPriceData()
        console.log("result: ", result);
        if (result.status === "success") {
            setShow(true);
            setPlan({ year: result.data[0].year, month: result.data[1].month })
            setLoading(false)
        }
    }

    useEffect(() => {
        getPlanPrice()
    }, [])

    useEffect(() => {
        if (plan) {
            selectedPrice()
        }
    }, [quantity, plan, checked])

    const handleAdd = () => {
        setQuantity(quantity + 1)
    }
    const handleRemove = () => {
        if (quantity > 2) {
            setQuantity(quantity - 1)
        } else {
            setQuantity(1)
        }
    }

    const selectedPrice = () => {
        if (checked) {
            const price = plan.month
            if (quantity > 0 && quantity < 6) {
                setPrice((price.filter((item) => item.up_to !== null && item.up_to === 5)[0].unit_amount / 100 * quantity).toFixed(2))
            } else if (quantity > 5 && quantity < 16) {
                setPrice((price.filter((item) => item.up_to !== null && item.up_to === 15)[0].unit_amount / 100 * quantity).toFixed(2))
            } else if (quantity > 15 && quantity < 31) {
                setPrice((price.filter((item) => item.up_to !== null && item.up_to === 30)[0].unit_amount / 100 * quantity).toFixed(2))
            } else if (quantity > 30) {
                setPrice(price.filter((item) => item.up_to === null)[0].flat_amount / 100)
            }
        } else {
            const price = plan.year
            if (quantity > 0 && quantity < 6) {
                setPrice((price.filter((item) => item.up_to !== null && item.up_to === 5)[0].unit_amount / 100 * quantity).toFixed(2))
            } else if (quantity > 5 && quantity < 16) {
                setPrice((price.filter((item) => item.up_to !== null && item.up_to === 15)[0].unit_amount / 100 * quantity).toFixed(2))
            } else if (quantity > 15 && quantity < 31) {
                setPrice((price.filter((item) => item.up_to !== null && item.up_to === 30)[0].unit_amount / 100 * quantity).toFixed(2))
            } else if (quantity > 30) {
                setPrice(price.filter((item) => item.up_to === null)[0].flat_amount / 100)
            }
        }
    }

    const handleTitle = (val) => {
        if (val === 5) {
            return "Para empresas de menos de 5 usuarios"
        } else if (val === 15) {
            return "Para empresas de entre 5 y 15 usuarios"
        } else if (val === 30) {
            return "Para empresas de entre 15 y 30 usuarios"
        } else if (val === null) {
            return "Para empresas de más de 30 usuarios"
        }
    }



    return (
        <>
            <Box sx={{ height: "100%" }}>
                <Grid spacing={2} container>
                    <Grid item xs={12} sm={12}>
                        <Button onClick={handlePrev} variant='text'>
                            <KeyboardBackspaceIcon sx={{ mr: 0.5, fontSize: "20px" }} />
                            <Typography sx={{ textTransform: "math-auto" }} variant='label'>Volver atrás</Typography>
                        </Button>
                    </Grid>
                    {show &&
                        <Grid sx={{ display: "flex", justifyContent: "center", height: "100%" }} item xs={12}>
                            <Box component={Paper} sx={{ maxWidth: "700px", p: 4, mx: 4, borderRadius: "16px" }}>
                                <Grid columnSpacing={2} container>
                                    <Grid item xs={5}>
                                        <Box sx={{ display: "flex", height: "100%", mx: 2, flexDirection: "column", alignItems: "center", background: "#F8F8F8", borderRadius: "16px", p: 2, rowGap: 0.5 }}>
                                            <Typography variant='h6'>Número de usuarios</Typography>
                                            <Typography sx={{ fontWeight: "400" }} variant='h1'>{quantity}</Typography>
                                            <Box>
                                                <IconButton size='small' onClick={handleRemove}><RemoveCircleOutlineRoundedIcon sx={{ color: "black" }} /></IconButton>
                                                <IconButton size='small' onClick={handleAdd}><AddCircleOutlineRoundedIcon sx={{ color: "black" }} /></IconButton>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <Box sx={{ display: "flex", height: "100%", mx: 2, flexDirection: "column", alignItems: "center", background: "#F8F8F8", borderRadius: "16px", p: 2, rowGap: 0.5 }}>
                                            <Typography variant='h6'>Coste total </Typography>
                                            <Typography sx={{ fontWeight: "400" }} variant='h1'>{price} €</Typography>
                                            <Typography variant='h6'>{checked ? "por mes" : "por año"}</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", columnGap: 1, mt: 2, mb: 3, position: "relative", zIndex: 2 }}>
                                            <Typography sx={{ color: "var(--Monochromatic-06, #999)" }} variant='label'>Anual</Typography>
                                            <ThemeSwitch
                                                checked={checked}
                                                onChange={handleChange}
                                            />
                                            <Typography sx={{ color: "var(--Monochromatic-06, #999)" }} variant='label'>Mensual</Typography>
                                        </Box>
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box>
                                            <TableContainer>
                                                <Table aria-label="simple table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell size='small'>Subscripciones</TableCell>
                                                            <TableCell size='small' align="center">Precio</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    {plan &&
                                                        <TableBody>
                                                            {checked ? plan.month.map((data, index) =>
                                                                <TableRow key={index}>
                                                                    <TableCell size='small' sx={{ display: "flex", alignItems: "center", columnGap: 1 }}>{userAddIcon}{handleTitle(data.up_to)}</TableCell>
                                                                    <TableCell size='small' align="center">{index === plan.month.length - 1 ? `${data.flat_amount / 100}€ /mes` : `${data.unit_amount / 100}€ por usuario/mes`}</TableCell>
                                                                </TableRow>
                                                            ) :
                                                                plan.year.map((data, index) =>
                                                                    <TableRow key={index}>
                                                                        <TableCell size='small' sx={{ display: "flex", alignItems: "center", columnGap: 1 }}>{userAddIcon}{handleTitle(data.up_to)}</TableCell>
                                                                        <TableCell size='small' align="center">{index === plan.year.length - 1 ? `${data.flat_amount / 1200}€ /mes` : `${data.unit_amount / 1200}€ por usuario/mes`}</TableCell>
                                                                    </TableRow>
                                                                )
                                                            }
                                                        </TableBody>
                                                    }
                                                </Table>
                                            </TableContainer>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    }
                    {/* <Grid sx={{ display: "flex", flexDirection: "column", rowGap: 1 }} item xs={12}>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", columnGap: 1, my: 1, position: "relative", zIndex: 2 }}>
                        <Typography sx={{ color: "var(--Monochromatic-06, #999)" }} variant='label'>Anual</Typography>
                        <ThemeSwitch
                            checked={checked}
                            onChange={handleChange}
                        />
                        <Typography sx={{ color: "var(--Monochromatic-06, #999)" }} variant='label'>Mensual</Typography>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", columnGap: 1, my: 1, position: "relative", zIndex: 2 }}>
                        <IconButton onClick={handleRemove}><IndeterminateCheckBoxIcon /></IconButton>
                        <Box sx={{ width: "50px", backgroundColor: "#fff", height: "30px", border: "1px solid black", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "8px" }}>
                            <Typography variant='h5'>{quantity}</Typography>
                        </Box>
                        <IconButton onClick={handleAdd}><AddBoxIcon /></IconButton>
                    </Box>
                    <Box sx={{ display: "flex", columnGap: 1, my: 1, position: "relative", justifyContent: "center", zIndex: 2 }}>
                        <Box component={Paper} sx={{ minWidth: "500px", p: 2 }}>
                            <Typography variant='h4'>Tiers</Typography>
                            <TableContainer>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>QUANTITY</TableCell>
                                            <TableCell align="right">PRICE PER PERSON</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    {plan &&
                                        <TableBody>
                                            {checked ? plan.year.map((data, index) =>
                                                <TableRow>
                                                    <TableCell>if total quantity is up to {data.up_to} </TableCell>
                                                    <TableCell align="right">€{data.unit_amount / 100}</TableCell>
                                                </TableRow>
                                            ) :
                                                plan.month.map((data, index) =>
                                                    <TableRow>
                                                        <TableCell>if total quantity is up to {data.up_to} </TableCell>
                                                        <TableCell align="right">€{data.unit_amount / 100}</TableCell>
                                                    </TableRow>
                                                )
                                            }
                                        </TableBody>
                                    }
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                </Grid> */}
                    {show &&
                        <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
                            <BasicThemeButton
                                onClick={() => handlePlanSelect(quantity, checked ? "monthly" : "yearly")}
                                title="Confirmar"
                                textVariant={"h5"}
                                style={{ px: 2, py: 1 }}
                                icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                    <path d="M13.2686 4.16878C13.5686 3.86882 13.9755 3.70032 14.3998 3.70032C14.8241 3.70032 15.231 3.86882 15.531 4.16878L22.731 11.3688C23.031 11.6688 23.1995 12.0757 23.1995 12.5C23.1995 12.9242 23.031 13.3311 22.731 13.6312L15.531 20.8312C15.2292 21.1226 14.8251 21.2839 14.4056 21.2803C13.986 21.2766 13.5847 21.1083 13.2881 20.8117C12.9914 20.515 12.8232 20.1137 12.8195 19.6942C12.8159 19.2747 12.9772 18.8705 13.2686 18.5688L17.5998 14.1H2.3998C1.97546 14.1 1.56849 13.9314 1.26843 13.6313C0.968376 13.3313 0.799805 12.9243 0.799805 12.5C0.799805 12.0756 0.968376 11.6687 1.26843 11.3686C1.56849 11.0685 1.97546 10.9 2.3998 10.9H17.5998L13.2686 6.43118C12.9687 6.13113 12.8001 5.72424 12.8001 5.29998C12.8001 4.87571 12.9687 4.46882 13.2686 4.16878Z" fill="white" />
                                </svg>}
                            />
                        </Grid>
                    }
                </Grid>
            </Box>
            {loading && <PageLoader />}
        </>
    )
}
