import { Box, CircularProgress } from '@mui/material'
import React from 'react'

export default function PageLoader() {
    return (
        <Box sx={{ display: "flex", zIndex: 9999, justifyContent: "center", alignItems: "center", position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}>
            <CircularProgress />
        </Box>
    )
}
