import { Button, Grid, MenuItem, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { ThemeInput, ThemeSelect } from '../shared/Input'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import AllCountry from '../country/countries.json'

export default function UserDetails({ data, setData, isFocused, handlePrev }) {

  return (
    <Grid container>
      <Grid item xs={12} sm={2.5}>
        <Button onClick={handlePrev} variant='text'>
          <KeyboardBackspaceIcon sx={{ mr: 0.5, fontSize: "20px" }} />
          <Typography sx={{ textTransform: "math-auto" }} variant='label'>Volver atrás</Typography>
        </Button>
      </Grid>
      <Grid item xs={12} sm={7.5}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <ThemeInput
              label="Nombre de la empresa"
              value={data.company.companyName}
              error={isFocused && !data.company.companyName}
              helperText={(isFocused && !data.company.companyName) && "Se requiere el Nombre de la empresa"}
              onChange={(e) => setData({ ...data, company: { ...data.company, companyName: e.target.value } })}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <ThemeInput
              label="Abreviatura del nombre"
              value={data.company.url}
              error={isFocused && !data.company.url}
              helperText={(isFocused && !data.company.url) && "Se requiere el Abreviatura del nombre"}
              onChange={(e) => setData({ ...data, company: { ...data.company, url: e.target.value } })}
            />
          </Grid>
          {/* <Grid item xs={12} sm={12}>
            <ThemeInput
              label="Número de empleado"
              value={data.company.employeesNumber}
              error={isFocused && !data.company.employeesNumber}
              helperText={(isFocused && !data.company.employeesNumber) && "Se requiere el Número de empleado"}
              onChange={(e) => setData({ ...data, company: { ...data.company, employeesNumber: e.target.value } })} />
          </Grid> */}
          <Grid item xs={12} sm={12}>
            <ThemeInput
              label="Industria"
              value={data.company.industry}
              error={isFocused && !data.company.industry}
              helperText={(isFocused && !data.company.industry) && "Se requiere el Industria"}
              onChange={(e) => setData({ ...data, company: { ...data.company, industry: e.target.value } })} />
          </Grid>
          <Grid item xs={12} sm={12}>
            <ThemeInput
              label="Dirección"
              value={data.company.address}
              error={isFocused && !data.company.address}
              helperText={(isFocused && !data.company.address) && "Se requiere el Dirección"}
              onChange={(e) => setData({ ...data, company: { ...data.company, address: e.target.value } })} />
          </Grid>
          <Grid item xs={4} sm={7.5}>
            <ThemeSelect
              label={"País"}
              value={data.company.country}
              error={isFocused && !data.company.country}
              helperText={(isFocused && !data.company.country) && "Se requiere el Country"}
              sx={{
                '.MuiInputBase-input': {
                  paddingInline: "0px !impotent"
                },
              }}
              onChange={(e) => setData({ ...data, company: { ...data.company, country: e.target.value } })}
              id="country"
              name="country"
            >
              {AllCountry.countries.map((item, index) =>
                <MenuItem key={index} value={item.name_es}>
                  {item.name_es}
                </MenuItem>
              )}
            </ThemeSelect>
          </Grid>
          <Grid item xs={8} sm={4.5}>
            <ThemeInput
              label="Provincia"
              value={data.company.state}
              error={isFocused && !data.company.state}
              helperText={(isFocused && !data.company.state) && "Se requiere el Provincia"}
              onChange={(e) => setData({ ...data, company: { ...data.company, state: e.target.value } })} />
          </Grid>
          <Grid item xs={8} sm={7.5}>
            <ThemeInput
              label="Ciudad"
              value={data.company.city}
              error={isFocused && !data.company.city}
              helperText={(isFocused && !data.company.city) && "Se requiere el Ciudad"}
              onChange={(e) => setData({ ...data, company: { ...data.company, city: e.target.value } })} />
          </Grid>
          <Grid item xs={4} sm={4.5}>
            <ThemeInput
              label="Código Postal"
              value={data.company.posteCode}
              error={isFocused && !data.company.posteCode}
              helperText={(isFocused && !data.company.posteCode) && "Se requiere el Código Postal"}
              onChange={(e) => setData({ ...data, company: { ...data.company, posteCode: e.target.value } })}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <ThemeInput
              label="NIF o CIF"
              value={data.company.cif}
              error={isFocused && !data.company.cif}
              helperText={(isFocused && !data.company.cif) && "Se requiere el NIF o CIF"}
              onChange={(e) => setData({ ...data, company: { ...data.company, cif: e.target.value } })} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
