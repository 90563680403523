import { Box, FormControlLabel, Grid, InputAdornment, MenuItem, Radio, RadioGroup, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import BasicThemeButton from '../shared/button'
import { ThemeInput, ThemeSelect } from '../shared/Input'
import { getCompanyData, updateData } from '../../controller/userController'
import PageLoader from '../shared/loader'
import AllCountry from '../country/countries.json'




export default function AccountData() {
    const [loading, setLoading] = useState(false)
    const [isFocused, setIsFocused] = useState(false)
    const [companyId, setCompanyId] = useState("")
    console.log("companyId: ", companyId);
    // const [confirmState, setConfirmState] = useState({ email: "", password: "" })
    // const [selectedCountry, setSelectedCountry] = useState(false)
    const [initialData, setInitialData] = useState({});
    const [userData, setUserData] = useState({
        companyName: "",
        address: "",
        industry: "",
        city: "",
        postalCode: "",
        state: "",
        country: "",
        userName: "",
        // phone: "",
        // displayName: "",
        // mail: "",
        // password: "",
    })

    const hasDataChanged = () => {
        return JSON.stringify(userData) !== JSON.stringify(initialData);
    };

    console.log("userData: ", userData);
    const getData = async () => {
        setLoading(true)
        const uid = {
            uid: localStorage.getItem('uid')
        }
        const result = await getCompanyData(uid)
        if (result.status === "success") {
            console.log("result: ", result);
            setCompanyId(result.data.user.company_id)
            setUserData({
                companyName: result.data.company.companyName,
                address: result.data.company.address,
                industry: result.data.company.industry,
                // addressLine2: result.data.company.addressLine2,
                city: result.data.company.city,
                postalCode: result.data.company.posteCode,
                state: result.data.company.state,
                country: result.data.company.country,
                phone: result.data.company.phone,
                // userName: "",
            })
            setInitialData({
                companyName: result.data.company.companyName,
                address: result.data.company.address,
                industry: result.data.company.industry,
                // addressLine2: result.data.company.addressLine2,
                city: result.data.company.city,
                postalCode: result.data.company.posteCode,
                state: result.data.company.state,
                country: result.data.company.country,
                phone: result.data.company.phone,
                // userName: "",
            })
            setLoading(false)
        }
    }

    useEffect(() => {
        getData()
    }, [])

    const updateCompany = async () => {
        setIsFocused(true)
        if (Object.values(userData).every(value => value !== "") && hasDataChanged()) {
            setLoading(true)
            const company = {
                company: {
                    uid: companyId,
                    data: { ...userData, company_id: companyId, }
                }
            }
            const result = await updateData(company)
            console.log("result: ", result);
            getData()
            setIsFocused(false)
        } else {
            setLoading(false)
        }
    }

    // const handleSelectChange = (event) => {
    //     setSelectedCountry(event.target.value);
    // };

    // const handleUserStore = async () => {
    //     console.log("userData: ", userData);
    //     setIsFocused(true)
    // }

    return (
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexDirection: "column", minHeight: "100%", p: 4 }}>
            <Box sx={{ width: { sm: "60%", xs: "100%" } }}>
                <Typography sx={{ fontSize: "26px", letterSpacing: "-1.6px", textAlign: "justify", fontWeight: 700 }} variant='h3'>Compruebe y modifique sus datos de  usuario.</Typography>
                {!loading &&
                    <Grid spacing={2} sx={{ mt: 2 }} container>
                        <Grid item xs={12}>
                            <ThemeInput
                                label="Nombre de la empresa"
                                type={"text"}
                                error={isFocused && !userData.companyName}
                                helperText={(isFocused && !userData.companyName) && "Se requiere el nombre"}
                                value={userData.companyName}
                                onChange={(e) => setUserData({ ...userData, companyName: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <ThemeInput
                                label="Dirección"
                                error={isFocused && !userData.address}
                                helperText={(isFocused && !userData.address) && "Se requiere el nombre"}
                                value={userData.address}
                                onChange={(e) => setUserData({ ...userData, address: e.target.value })}
                                type={"text"}
                            />
                        </Grid>
                        {/* <Grid item xs={12}>
                            <ThemeInput
                                label="Dirección línea 2 (opcional)"
                                error={isFocused && !userData.addressLine2}
                                helperText={(isFocused && !userData.addressLine2) && "Se requiere el nombre"}
                                value={userData.addressLine2}
                                onChange={(e) => setUserData({ ...userData, addressLine2: e.target.value })}
                                type={"text"}
                            />
                        </Grid> */}
                        <Grid item xs={12} sm={12}>
                            <ThemeInput
                                label="Industria"
                                value={userData.industry}
                                error={isFocused && !userData.industry}
                                helperText={(isFocused && !userData.industry) && "Se requiere el Industria"}
                                onChange={(e) => setUserData({ ...userData, industry: e.target.value })} />
                        </Grid>
                        <Grid item sm={8} xs={12} >
                            {/* <ThemeInput
                            label="País"

                            onChange={(e) => setUserData({ ...userData, country: e.target.value })}
                            type={"text"}
                        /> */}
                            <ThemeSelect
                                label={"País"}
                                error={isFocused && !userData.country}
                                helperText={(isFocused && !userData.country) && "Se requiere el nombre"}
                                value={userData.country}
                                sx={{
                                    '.MuiInputBase-input': {
                                        paddingInline: "0px !impotent"
                                    },
                                }}
                                onChange={(e) => setUserData({ ...userData, country: e.target.value })}
                                id="countryCodes"
                                name="countryCodes"
                            >
                                {AllCountry.countries.map((item, index) =>
                                    <MenuItem key={index} value={item.name_es}>
                                        {item.name_es}
                                    </MenuItem>
                                )}
                            </ThemeSelect>
                        </Grid>
                        {/* <Grid item sm={4} xs={12}>
                            <ThemeInput
                                label="Teléfono"
                                value={userData.phone}
                                error={isFocused && !userData.phone}
                                helperText={(isFocused && !userData.phone) && "Se requiere el Teléfono"}
                                onChange={(e) => setUserData({ ...userData, phone: e.target.value })}
                                type={"number"}
                            // InputProps={{
                            //     startAdornment: <InputAdornment position="start">{userData.country ? "+" + userData.country : ""}</InputAdornment>,
                            // }}
                            />
                        </Grid> */}
                        <Grid item sm={4} xs={12}>
                            <ThemeInput
                                label="Provincia"
                                error={isFocused && !userData.state}
                                helperText={(isFocused && !userData.state) && "Se requiere el nombre"}
                                value={userData.state}
                                onChange={(e) => setUserData({ ...userData, state: e.target.value })}
                                type={"text"}
                            />
                        </Grid>
                        <Grid item sm={8} xs={12}>
                            <ThemeInput
                                label="Ciudad"
                                error={isFocused && !userData.city}
                                helperText={(isFocused && !userData.city) && "Se requiere el nombre"}
                                value={userData.city}
                                onChange={(e) => setUserData({ ...userData, city: e.target.value })}
                                type={"text"}
                            />
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <ThemeInput
                                label="Código Postal"
                                error={isFocused && !userData.postalCode}
                                helperText={(isFocused && !userData.postalCode) && "Se requiere el nombre"}
                                value={userData.postalCode}
                                onChange={(e) => setUserData({ ...userData, postalCode: e.target.value })}
                                type={"number"}
                            />
                        </Grid>
                        {/* <Grid item xs={12}>
                            <ThemeInput
                                label="Nombre usuario administrador"
                                error={isFocused && !userData.userName}
                                helperText={(isFocused && !userData.userName) && "Se requiere el nombre"}
                                value={userData.userName}
                                onChange={(e) => setUserData({ ...userData, userName: e.target.value })}
                                type={"text"}
                            />
                        </Grid> */}
                        {/* <Grid item xs={12}>
                        <ThemeInput
                            label="Nombre a mostrar"
                            error={isFocused && !userData.displayName}
                            helperText={(isFocused && !userData.displayName) && "Se requiere el nombre"}
                            value={userData.displayName}
                            onChange={(e) => setUserData({ ...userData, displayName: e.target.value })}
                            type={"text"}
                        />
                    </Grid> */}
                        {/* <Grid item sm={6} xs={12}>
                        <ThemeInput
                            label="Email"
                            error={isFocused && !userData.mail}
                            helperText={(isFocused && !userData.mail) && "Se requiere el nombre"}
                            value={userData.mail}
                            onChange={(e) => setUserData({ ...userData, mail: e.target.value })}
                            type={"text"}
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <ThemeInput
                            label="Confirm Email"
                            error={isFocused && !confirmState.email}
                            helperText={(isFocused && !confirmState.email) && "Se requiere el nombre"}
                            value={confirmState.email}
                            onChange={(e) => setConfirmState({ ...confirmState, email: e.target.value })}
                            type={"text"}
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <ThemeInput
                            label="password"
                            error={isFocused && !userData.password}
                            helperText={(isFocused && !userData.password) && "Se requiere el nombre"}
                            value={userData.password}
                            onChange={(e) => setUserData({ ...userData, password: e.target.value })}
                            type={"password"}
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <ThemeInput
                            label="Confirm password"
                            error={isFocused && !confirmState.password}
                            helperText={(isFocused && !confirmState.password) && "Se requiere el nombre"}
                            value={confirmState.password}
                            onChange={(e) => setConfirmState({ ...confirmState, password: e.target.value })}
                            type={"password"}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{ width: "85%" }}>
                            <Typography>¿La dirección fiscal es la misma que la dirección anteriormente puesta?</Typography>
                        </Box>
                        <Box>

                        </Box>
                    </Grid> */}
                    </Grid>
                }
            </Box>
            {/* {!loading &&
                <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%", mt: 6 }}>
                    <BasicThemeButton
                        title={"Siguiente"}
                        onClick={updateCompany}
                        textVariant="h4"
                        icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                            <path d="M13.2686 4.16878C13.5686 3.86882 13.9755 3.70032 14.3998 3.70032C14.8241 3.70032 15.231 3.86882 15.531 4.16878L22.731 11.3688C23.031 11.6688 23.1995 12.0757 23.1995 12.5C23.1995 12.9242 23.031 13.3311 22.731 13.6312L15.531 20.8312C15.2292 21.1226 14.8251 21.2839 14.4056 21.2803C13.986 21.2766 13.5847 21.1083 13.2881 20.8117C12.9914 20.515 12.8232 20.1137 12.8195 19.6942C12.8159 19.2747 12.9772 18.8705 13.2686 18.5688L17.5998 14.1H2.3998C1.97546 14.1 1.56849 13.9314 1.26843 13.6313C0.968376 13.3313 0.799805 12.9243 0.799805 12.5C0.799805 12.0756 0.968376 11.6687 1.26843 11.3686C1.56849 11.0685 1.97546 10.9 2.3998 10.9H17.5998L13.2686 6.43118C12.9687 6.13113 12.8001 5.72424 12.8001 5.29998C12.8001 4.87571 12.9687 4.46882 13.2686 4.16878Z" fill="white" />
                        </svg>}
                        style={{
                            padding: "16px 24px",
                            textTransform: "capitalize",
                        }}
                    />
                </Box>
            } */}
            {loading && <PageLoader />}
        </Box>
    )
}
