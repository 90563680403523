const Typography = (theme) => {
  return {
    h1: {
      fontWeight: 700,
      lineHeight: 1,
      color: "#4D4D4D",
      fontFamily: "IBM Plex Sans",
      fontSize: "50px",
      marginBottom: "0px",
      letterSpacing: "-4px",
      [theme.breakpoints.down("md")]: {
        fontSize: "30px",
        lineHeight: "30px",
        letterSpacing: "0",
      },
    },
    h2: {
      fontFamily: "IBM Plex Sans",
      fontSize: "35px",
      fontWeight: 600,
      letterSpacing: "-1.5px",
      marginBottom: "0px",
      [theme.breakpoints.down("md")]: {
        fontSize: "18px",
        lineHeight: "18px",
        letterSpacing: "0",
      },
    },
    h3: {
      fontFamily: "IBM Plex Sans",
      color: "#4D4D4D",
      fontSize: "32px",
      fontWeight: 500,
      marginBottom: "0px",
      lineHeight: "37px",
      letterSpacing: "-1.8px",
      [theme.breakpoints.down("md")]: {
        fontSize: "16px",
        lineHeight: "20px",
        letterSpacing: "0",
      },
    },
    h4: {
      fontFamily: "IBM Plex Sans",
      fontSize: "22px",
      fontWeight: 700,
      marginBottom: "0px",
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
        letterSpacing: "0",
      },
    },
    h5: {
      fontFamily: "IBM Plex Sans",
      fontSize: "20px",
      fontWeight: 700,
      marginBottom: "0px",
    },
    h6: {
      fontFamily: "IBM Plex Sans",
      fontSize: "14px",
      fontWeight: 700,
      marginBottom: "0px",
    },
    subtitle1: {
      fontFamily: "IBM Plex Sans",
      fontSize: "18px",
      fontWeight: 400,
      marginBottom: "0px",
    },
    label: {
      fontFamily: "IBM Plex Sans",
      fontSize: "14px",
      letterSpacing: "-0.8px",
      fontWeight: 400,
      [theme.breakpoints.down("md")]: {
        fontSize: "12px",
      }
    },
    label2: {
      fontSize: "14px",
      fontWeight: 400,
      [theme.breakpoints.down("md")]: {
        fontSize: "13px",
      }
    },
    subtitle2: {
      fontSize: "12px",
      fontWeight: 400,
    },
    subtitle3: {
      fontSize: "10px",
      fontWeight: 400,
    },
  };
};

export default Typography;
