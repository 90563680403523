export const getPriceData = async (data) => {
    try {
        const response = await fetch("https://europe-west3-zoa-suite.cloudfunctions.net/stripe-get-pricing", {
            method: "GET",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const responseData = await response.json();
        console.log("data: ", responseData);

        return { status: "success", data: responseData };
    } catch (error) {
        console.error("Error during fetch:", error);
        return { status: "error", error: error.message };
    }
};