import React, { useEffect, useState } from 'react'
import MainLayout from '../../component/layout/mainLayout'
import { Box, Grid, IconButton, Step, StepLabel, Stepper, Typography } from '@mui/material'
import GradientCard from '../../component/shared/gradientCard'
import DoneIcon from '@mui/icons-material/Done';
// const steps = ['Sign Up', 'Información de la cuenta', 'Introduce datos de pago'];
import UserInfo from '../../component/proof/userInfo';
import UserDetails from '../../component/proof/userDetails';
import BasicThemeButton from '../../component/shared/button';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { AddUserData, getApi, paymentRequest } from '../../controller/auth';
import PageLoader from '../../component/shared/loader';
import { useNavigate } from 'react-router-dom';
import { loadStripe } from "@stripe/stripe-js";
import { toast } from 'react-toastify';
import { atom, useRecoilState } from 'recoil';
import PlanSelect from '../../component/proof/plan';
import { getCompanyData, updateData } from '../../controller/userController';
import {
    EmbeddedCheckoutProvider,
    EmbeddedCheckout
} from '@stripe/react-stripe-js';


const userIdState = atom({
    key: 'uid',
    default: localStorage.getItem('uid') || null,
});

const stripePromise = loadStripe("pk_test_51OEUQvEh3IAGDIkE7ruQnJneRT6xd6NC3qOhMxI9K6P1TkAZLcr6YEkcIIqKl832zh49QIXMY9EjugOyBUL6p0yG00qJYu7TxD");

export default function Register() {
    const [activeStep, setActiveStep] = useState(0);
    const [ipAddress, setIpAddress] = useState({})
    const [loading, setLoading] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState(false)
    const [userId, setUserId] = useRecoilState(userIdState);
    const [isFocused, setIsFocused] = useState(false)
    const [selectedCountry, setSelectedCountry] = useState('+34');
    const [phoneNumber, setPhoneNumber] = useState("")
    const navigate = useNavigate()
    const [confirmPassword, setConfirmPassword] = useState("");
    const [clientSecret, setClientSecret] = useState("");
    const [initialData, setInitialData] = useState({});
    const [show, setShow] = useState(true);
    const [policyChecked, setPolicyChecked] = useState(false);
    const [data, setData] = useState({
        user: {
            userName: "",
            userSurname: "",
            rol: "",
            age: "",
            phoneNumber: "",
            mail: "",
            password: "",
            privacyPolicy: ""
        },
        company: {
            companyName: "",
            url: "",
            // employeesNumber: "",
            industry: "",
            address: "",
            city: "",
            posteCode: "",
            state: "",
            country: "España",
            cif: ""
        }
    })

    const ApiAddress = async () => {
        const result = await getApi()
        if (result.status === "success") {
            setIpAddress({ ip: result.data.ip, ts: new Date() });
        }
    }

    useEffect(() => {
        ApiAddress()
    }, [])

    useEffect(() => {
        if (policyChecked && ipAddress.ip) {
            setData({ ...data, user: { ...data.user, privacyPolicy: ipAddress } })
        } else {
            setData({ ...data, user: { ...data.user, privacyPolicy: "" } })
        }
    }, [policyChecked])

    const hasDataChanged = () => {
        return JSON.stringify(data) !== JSON.stringify(initialData);
    };

    const isMobile = window.innerWidth <= 600;

    useEffect(() => {
        setData({ ...data, user: { ...data.user, phoneNumber: selectedCountry + "-" + phoneNumber } })
    }, [selectedCountry, phoneNumber])


    useEffect(() => {
        if (userId) {
            getUser()
        }
    }, [])

    const getUser = async () => {
        setShow(false)
        setLoading(true);
        const userId = localStorage.getItem("uid")
        const uid = {
            uid: userId
        }
        const result = await getCompanyData(uid)
        if (result.status === "success") {
            setShow(true)
            setData(result.data)
            setInitialData(result.data)
            const number = result.data.user.phoneNumber
            const parts = number.split('-');
            const firstPart = parts[0];
            const secondPart = parts[1];
            setSelectedCountry(firstPart)
            setPhoneNumber(secondPart)
            setLoading(false);
        }
        else {
            setLoading(false);
        }
    }


    // const appearance = {
    //     theme: 'stripe',
    // };
    const options = {
        clientSecret,
    };

    const StepIcon = (props) => {
        const { completed } = props;

        return (
            <Box
                sx={{
                    border: "2px solid #F5FDFF",
                    p: 0.5,
                    borderRadius: "50%",
                    color: "#F5FDFF",
                }}
            >
                <Box sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "0.5px solid #F5FDFF",
                    p: 0.5,
                    borderRadius: "50%",
                    color: "#F5FDFF",
                    width: "25px",
                    height: "25px",
                }}>
                    {completed ? <DoneIcon /> : null}
                </Box>
            </Box>
        );
    };

    // const handleNext = async () => {
    //     console.log("activeStep: ", activeStep);
    //     if (activeStep === 0) {
    //         setActiveStep((prevActiveStep) => prevActiveStep + 1);
    //     } else if (activeStep === 1) {
    //         setActiveStep((prevActiveStep) => prevActiveStep + 1);
    //     } else if (activeStep === 2) {
    //         setActiveStep((prevActiveStep) => prevActiveStep + 1);
    //     }
    // };

    const handleNext = async () => {
        setIsFocused(true)
        if (
            (
                activeStep === 0 &&
                Object.values(data.user).every(value => value !== "") &&
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+.])[\w\d\s.!@#$%^&*()_+]+$/.test(data.user.password) &&
                data.user.password === confirmPassword &&
                selectedCountry &&
                !userId
            ) ||
            (activeStep === 0 && userId)
        ) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            setIsFocused(false)
        }
        else if (
            activeStep === 1 &&
            Object.values(data.company).every(value => value !== "") &&
            !userId
        ) {
            setLoading(true);
            setIsFocused(true)
            const result = await AddUserData(data)
            if (result.success) {
                const userId = result.data.user.uid;
                setUserId(userId)
                // toast.success(result.data.msg)
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                setLoading(false);
            } else {
                setLoading(false)
                toast.error(result.msg)
            }
        }
        else if (
            activeStep === 1 &&
            Object.values(data.company).every(value => value !== "") &&
            userId
        ) {
            if (hasDataChanged()) {
                setLoading(true);
                setIsFocused(true)
                const newData = {
                    user: {
                        uid: userId,
                        data: data.user
                    },
                    company: {
                        uid: data.user.company_id,
                        data: data.company
                    }
                }
                const result = await updateData(newData)
                if (result.status === "success") {
                    // toast.success(result.data.msg)
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                    setLoading(false);
                } else {
                    setLoading(false)
                    toast.error(result.data.msg)
                }
            } else {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
        }
    };

    console.log("activeStep: ", activeStep);
    const handlePrev = () => {
        if (activeStep > 0) {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        } else if (activeStep === 0) {
            navigate("/")
        }
    };

    const handlePlanSelect = async (userQuantity, duration) => {
        if (userQuantity > 0) {
            setLoading(true);
            const userId = localStorage.getItem('uid')
            const data = { userQuantity: userQuantity, duration: duration, uid: userId }
            const result = await paymentRequest(data)
            setClientSecret(result.client_secret);
            setSelectedPlan(true)
            setLoading(false);
        }
        else {
            toast.error("Please add user quantity")
        }
    }

    const handleSelectChange = (event) => {
        setSelectedCountry(event.target.value);
    };

    const handleLogout = () => {
        localStorage.removeItem('uid');
        setUserId(null);
        navigate("/login");
        // setData({
        //     user: {
        //         userName: "",
        //         userSurname: "",
        //         rol: "",
        //         age: "",
        //         phoneNumber: "",
        //         mail: "",
        //         password: "",
        //         privacyPolicy: ""
        //     },
        //     company: {
        //         companyName: "",
        //         url: "",
        //         employeesNumber: "",
        //         industry: "",
        //         address: "",
        //         city: "",
        //         posteCode: "",
        //         state: "",
        //         country: "",
        //         cif: ""
        //     }
        // })
        // setActiveStep(0)
    };

    return (
        <MainLayout>
            <Box sx={{ py: { sm: "20px", xs: 1 }, height: { sm: "95vh", xs: "auto" } }}>
                <GradientCard sx={{ height: "100%", overflow: "hidden" }}>
                    <Grid container height={"100%"}>
                        <Grid item xs={12} sm={3.5}>
                            <Box sx={{ width: "100%", height: "100%", background: "#0598B9", p: 4, display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "flex-start" }}>
                                <Box>
                                    <Typography sx={{ fontSize: "26px", letterSpacing: "-1.6px", color: "white" }} variant='h3'>Bienvenido a ZOA</Typography>
                                    <Typography sx={{ color: "white", fontWeight: 500 }} variant='label'>Estás a punto de adentrarte en la innovadora manera de administrar tus capacidades, recursos y entorno.</Typography>
                                    <Box sx={{ my: 2, width: "100%", display: "flex", flexDirection: "column", rowGap: 2 }}>
                                        {isMobile ?
                                            <Stepper
                                                activeStep={activeStep}
                                                sx={{
                                                    "& .MuiStepConnector-root": { ml: "17.5px" },
                                                    "& .MuiStepLabel-root": { p: 0 },
                                                    flexDirection: 'column',
                                                }}
                                                orientation="vertical"
                                            >
                                                {activeStep === 0 && (
                                                    <Step key={0}>
                                                        <StepLabel StepIconComponent={StepIcon}>
                                                            <Typography variant='label' sx={{ color: "#F5FDFF" }}>Sign Up</Typography>
                                                        </StepLabel>
                                                    </Step>
                                                )}
                                                {activeStep === 1 && (
                                                    <Step key={1}>
                                                        <StepLabel StepIconComponent={StepIcon}>
                                                            <Typography variant='label' sx={{ color: "#F5FDFF" }}>Información de la cuenta</Typography>
                                                        </StepLabel>
                                                    </Step>
                                                )}
                                                {activeStep === 2 && (
                                                    <Step key={2}>
                                                        <StepLabel StepIconComponent={StepIcon}>
                                                            <Box sx={{ display: "flex", flexDirection: "column", rowGap: 0.25 }}>
                                                                <Typography variant='label' sx={{ color: "#F5FDFF" }}>Introduce datos de pago</Typography>
                                                                <Typography variant='label' sx={{ color: "#F5FDFF", fontSize: "10px" }}>No se te cobrará hasta dentro de 15 días, y puedes cancelar en cualquier momento.</Typography>
                                                            </Box>
                                                        </StepLabel>
                                                    </Step>
                                                )}
                                            </Stepper>
                                            :
                                            <Stepper activeStep={activeStep}
                                                sx={{ "& .MuiStepConnector-root": { ml: "17.5px" }, "& .MuiStepLabel-root": { p: 0 } }}
                                                orientation="vertical"
                                            >
                                                <Step>
                                                    <StepLabel StepIconComponent={StepIcon}>
                                                        <Typography variant='label' sx={{ color: "#F5FDFF" }}>Registro</Typography>
                                                    </StepLabel>
                                                </Step>
                                                <Step>
                                                    <StepLabel StepIconComponent={StepIcon} >
                                                        <Typography variant='label' sx={{ color: "#F5FDFF" }}>Información de la cuenta</Typography>
                                                    </StepLabel>
                                                </Step>
                                                <Step>
                                                    <StepLabel StepIconComponent={StepIcon}>
                                                        <Box sx={{ display: "flex", flexDirection: "column", rowGap: 0.25 }}>
                                                            <Typography variant='label' sx={{ color: "#F5FDFF" }}>Introduce datos de pago</Typography>
                                                            <Typography variant='label' sx={{ color: "#F5FDFF", fontSize: "10px" }}>No se te cobrará hasta dentro de 15 días, y puedes cancelar en cualquier momento.</Typography>
                                                        </Box>
                                                    </StepLabel>
                                                </Step>
                                            </Stepper>
                                        }
                                    </Box>
                                </Box>
                                {userId ?
                                    <Box onClick={handleLogout} sx={{ display: "flex", alignItems: "center", columnGap: 1, cursor: "pointer" }}>
                                        <IconButton sx={{ padding: "0px" }} variant='text'>
                                            <Box sx={{ width: { sm: "30px", xs: "20px" }, height: { sm: "30px", xs: "20px" } }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" fill="none">
                                                    <path d="M30 24H4M4 24L11 18M4 24L11 30" stroke="#AEF0FF" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M18.0039 14C18.0279 9.65 18.2219 7.294 19.7579 5.758C21.5159 4 24.3439 4 29.9999 4H31.9999C37.6579 4 40.4859 4 42.2439 5.758C43.9999 7.514 43.9999 10.344 43.9999 16V32C43.9999 37.656 43.9999 40.486 42.2439 42.242C40.7059 43.78 38.3499 43.972 33.9999 43.996M18.0039 34C18.0279 38.35 18.2219 40.706 19.7579 42.242C21.0399 43.526 22.8939 43.872 25.9999 43.966" stroke="#AEF0FF" stroke-width="3" stroke-linecap="round" />
                                                </svg>
                                            </Box>
                                        </IconButton>
                                        <Typography sx={{ color: "#fff", display: { sm: "block", xs: "none" } }} variant='label'>Cerrar sesión</Typography>
                                    </Box>
                                    :
                                    <Box onClick={() => navigate("/user-check")} sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
                                        <IconButton>
                                            <AccountCircleIcon sx={{ color: "#fff" }} />
                                        </IconButton>
                                        <Typography variant='label' sx={{ color: "#fff" }}>Ya soy  usuario, iniciar sesión.</Typography>
                                    </Box>
                                }
                            </Box>
                        </Grid>
                        <Grid sx={{ height: "100%", overflowY: "auto", position: "relative" }} item xs={12} sm={8.5}>
                            <>
                                {(show) &&
                                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%", p: 4 }}>
                                        {activeStep === 0 &&
                                            <UserInfo
                                                setData={setData}
                                                setPolicyChecked={setPolicyChecked}
                                                policyChecked={policyChecked}
                                                data={data}
                                                confirmPassword={confirmPassword}
                                                setConfirmPassword={setConfirmPassword}
                                                handleSelectChange={handleSelectChange}
                                                selectedCountry={selectedCountry}
                                                setPhoneNumber={setPhoneNumber}
                                                phoneNumber={phoneNumber}
                                                isFocused={isFocused}
                                                handlePrev={handlePrev}
                                                userId={userId}
                                            />
                                        }
                                        {activeStep === 1 &&
                                            <UserDetails
                                                setData={setData}
                                                data={data}
                                                isFocused={isFocused}
                                                handlePrev={handlePrev}
                                            />
                                        }
                                        {activeStep === 2 &&
                                            <>
                                                {selectedPlan ?
                                                    <Box width={"100%"}>
                                                        {clientSecret && (
                                                            <EmbeddedCheckoutProvider
                                                                stripe={stripePromise}
                                                                options={options}
                                                            >
                                                                <EmbeddedCheckout />
                                                            </EmbeddedCheckoutProvider>
                                                        )}
                                                    </Box>
                                                    :
                                                    <PlanSelect handlePrev={handlePrev} setSelectedPlan={setSelectedPlan} handlePlanSelect={handlePlanSelect} />
                                                }
                                            </>
                                        }
                                        {(activeStep === 2) ?
                                            <>
                                            </>
                                            :
                                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 6 }}>
                                                <Box sx={{ flex: '1 1 auto' }} />
                                                <BasicThemeButton
                                                    onClick={handleNext}
                                                    title="Siguiente"
                                                    textVariant={"h5"}
                                                    style={{ px: 2, py: 1 }}
                                                    icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                                        <path d="M13.2686 4.16878C13.5686 3.86882 13.9755 3.70032 14.3998 3.70032C14.8241 3.70032 15.231 3.86882 15.531 4.16878L22.731 11.3688C23.031 11.6688 23.1995 12.0757 23.1995 12.5C23.1995 12.9242 23.031 13.3311 22.731 13.6312L15.531 20.8312C15.2292 21.1226 14.8251 21.2839 14.4056 21.2803C13.986 21.2766 13.5847 21.1083 13.2881 20.8117C12.9914 20.515 12.8232 20.1137 12.8195 19.6942C12.8159 19.2747 12.9772 18.8705 13.2686 18.5688L17.5998 14.1H2.3998C1.97546 14.1 1.56849 13.9314 1.26843 13.6313C0.968376 13.3313 0.799805 12.9243 0.799805 12.5C0.799805 12.0756 0.968376 11.6687 1.26843 11.3686C1.56849 11.0685 1.97546 10.9 2.3998 10.9H17.5998L13.2686 6.43118C12.9687 6.13113 12.8001 5.72424 12.8001 5.29998C12.8001 4.87571 12.9687 4.46882 13.2686 4.16878Z" fill="white" />
                                                    </svg>}
                                                />
                                            </Box>
                                        }
                                    </Box>
                                }
                            </>
                            {loading && <PageLoader />}
                        </Grid>
                    </Grid>
                </GradientCard>
            </Box>
        </MainLayout>
    )
}
