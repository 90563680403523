import { Box, Grid, Link, Typography } from '@mui/material'
import React from 'react'
import GradientCard from '../shared/gradientCard'
import {ThemeInput} from '../shared/Input'
import BasicThemeButton from '../shared/button'

export default function FooterSection() {
    return (
        <Grid sx={{ position: "relative" }} spacing={{ sm: "50px", xs: "20px" }} container>
            <Grid item xs={12} md={9}>
                <GradientCard
                    sx={{ height: '100%', p: 4 }}
                >
                    <Grid container>
                        <Grid item xs={12} sm={5}>
                            <Box component={"img"} sx={{ position: "absolute", height: "100%", top: -20, left: 90, zIndex: 2, display: { sm: "block", xs: "none" } }} src="/images/chica-hero.png" alt="" />
                            <Box component={"img"} sx={{ position: "absolute", height: "95%", top: 0, left: 80, display: { sm: "block", xs: "none" } }} src="/images/vector/vector1.svg" alt="" />
                        </Grid>
                        <Grid item xs={12} sm={7}>
                            <Box
                                sx={{
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    rowGap: 2,
                                    alignItems: "center",
                                    textAlign: "center",
                                    justifyContent: "center"
                                }}
                            >
                                <Typography sx={{ fontWeight: 600 }} variant='h2'>Quiere saber más de ZOA</Typography>
                                <Typography sx={{ width: { sm: "350px", xs: "100%" } }} variant='label'>Déjenos su email y le informaremos de las nuevas funciones y ofertas de ZOA</Typography>
                                <ThemeInput
                                    type={"email"}
                                    sx={{
                                        width: { sm: "350px", xs: "100%" },
                                        '& .MuiInputBase-input': {
                                            padding: 2,
                                        },
                                    }}
                                />
                                <BasicThemeButton
                                    title={"Suscríbete"}
                                    textVariant={"h4"}
                                    icon={<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                                        <path d="M3.006 13.9545C3.08214 12.217 3.82595 10.576 5.08237 9.37351C6.3388 8.17102 8.01087 7.49988 9.75 7.5H38.25C39.9891 7.49988 41.6612 8.17102 42.9176 9.37351C44.1741 10.576 44.9179 12.217 44.994 13.9545L42.78 15.1845L24 25.296L5.22 15.1845L3.006 13.9545ZM3 17.382V33.75C3 35.5402 3.71116 37.2571 4.97703 38.523C6.2429 39.7888 7.95979 40.5 9.75 40.5H38.25C40.0402 40.5 41.7571 39.7888 43.023 38.523C44.2888 37.2571 45 35.5402 45 33.75V17.382L44.211 17.8215L24.711 28.3215C24.4925 28.4391 24.2482 28.5007 24 28.5007C23.7518 28.5007 23.5075 28.4391 23.289 28.3215L3.771 17.811L3 17.382Z" fill="white" />
                                    </svg>}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </GradientCard>
            </Grid>
            <Grid item xs={12} md={3}>
                <GradientCard
                    sx={{
                        height: '100%',
                        px: 2,
                        py: 4,
                        display: 'flex',
                        flexDirection: "column",
                        justifyContent: "space-between",
                        rowGap: 2,
                    }}
                >
                    <Link style={{ textDecoration: "auto" }} to="/" >
                        <Box sx={{ my: 1, display: "flex", columnGap: 2, justifyContent: "center", alignItems: "center" }}>
                            <Box component={"img"} src="/images/Logo.svg" sx={{ width: { sm: "80px", xs: "32px" }, height: "auto" }} alt='Logo' />
                            <Typography sx={{ fontWeight: 700, fontSize: { sm: "80px", xs: "32px" } }} variant='h1'>ZOA</Typography>
                        </Box>
                    </Link>
                    <Box sx={{ display: "flex", flexDirection: { xs: "row", sm: "column" }, gap: 0.5, alignItems: "center", justifyContent: "center" }}>
                        <Box sx={{ display: "flex", flexDirection: { xs: "row", sm: "column" }, alignItems: "center" }}>
                            <Typography variant='label'>Calle Juela, 12</Typography>
                            <Typography variant='label'>46022 Valencia</Typography>
                        </Box>
                        <Typography variant='label'>Aviso legal</Typography>
                        <Typography variant='label'>ZOA © 2023</Typography>
                    </Box>
                </GradientCard>
            </Grid>
        </Grid>
    )
}
