// ** MUI Theme Provider
import { deepmerge } from "@mui/utils";

// ** Theme Override Imports
import palette from "./palette";
// import { spacing } from "./spacing";
const themeOptions = (settings) => {
  // ** Vars
  const theme = settings;

  const themeConfig = {
    palette: palette(theme),
    typography: {
      // button: {
      //   textTransform: "none",
      //   '&:hover': {
      //     background: 'none',
      //   },
      // },
      fontFamily: ["IBM Plex Sans"].join(","),
    },
    components: {
      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontWeight: 'bold',
            transform: "translate(0, 3px) scale(0.75)",
            "&.Mui-focused": {
              transform: "translate(0, -4px) scale(0.75)"
            },
          },
        }
      },
      MuiButtonBase: {
        styleOverrides: {
          root: {
            boxShadow: 'none', // Remove box shadow from the button
            '&:hover': {
              boxShadow: 'none', // Remove box shadow when hovered
            },
          },
        },
      },
    },
  };

  return deepmerge(themeConfig, {
    // palette: {
    //   primary: {
    //     ...themeConfig.palette[theme],
    //   },
    // },
  });
};

export default themeOptions;
