import React from 'react'
import MainLayout from '../../component/layout/mainLayout'
import { Box, Grid, Step, StepLabel, Stepper, Typography } from '@mui/material'
import GradientCard from '../../component/shared/gradientCard'
import DoneIcon from '@mui/icons-material/Done';
import BasicThemeButton from '../../component/shared/button';
import { useNavigate } from 'react-router-dom';


export default function Success() {
    const navigate = useNavigate()

    const StepIcon = (props) => {
        const { completed } = props;

        return (
            <Box
                sx={{
                    border: "2px solid #F5FDFF",
                    p: 0.5,
                    borderRadius: "50%",
                    color: "#F5FDFF",
                }}
            >
                <Box sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "0.5px solid #F5FDFF",
                    p: 0.5,
                    borderRadius: "50%",
                    color: "#F5FDFF",
                    width: "25px",
                    height: "25px",
                }}>
                    {completed ? <DoneIcon /> : null}
                </Box>
            </Box>
        );
    };

    return (
        <MainLayout>
            <Box sx={{ py: "20px", height: "90vh" }}>
                <GradientCard sx={{ height: "100%", overflow: "hidden" }}>
                    <Grid container height={"100%"}>
                        <Grid item xs={12} sm={3.5}>
                            <Box sx={{ width: "100%", height: "100%", background: "#0598B9", p: 4, display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "flex-start" }}>
                                <Box>
                                    <Typography sx={{ fontSize: "26px", letterSpacing: "-1.6px", color: "white" }} variant='h3'>Bienvenido a ZOA</Typography>
                                    <Typography sx={{ color: "white", fontWeight: 500 }} variant='label'>Estás a punto de adentrarte en la innovadora manera de administrar tus capacidades, recursos y entorno.</Typography>
                                    <Box sx={{ my: 2, width: "100%", display: "flex", flexDirection: "column", rowGap: 2 }}>
                                        <Stepper activeStep={3} sx={{ "& .MuiStepConnector-root": { ml: "17.5px" }, "& .MuiStepLabel-root": { p: 0 } }} orientation="vertical">
                                            <Step>
                                                <StepLabel StepIconComponent={StepIcon}>
                                                    <Typography variant='label' sx={{ color: "#F5FDFF" }}>Sign Up</Typography>
                                                </StepLabel>
                                            </Step>
                                            <Step>
                                                <StepLabel StepIconComponent={StepIcon} >
                                                    <Typography variant='label' sx={{ color: "#F5FDFF" }}>Información de la cuenta</Typography>
                                                </StepLabel>
                                            </Step>
                                            <Step>
                                                <StepLabel StepIconComponent={StepIcon}>
                                                    <Box sx={{ display: "flex", flexDirection: "column", rowGap: 0.25 }}>
                                                        <Typography variant='label' sx={{ color: "#F5FDFF" }}>Introduce datos de pago</Typography>
                                                        <Typography variant='label' sx={{ color: "#F5FDFF", fontSize: "10px" }}>No se te cobrará hasta dentro de 15 días, y puedes cancelar en cualquier momento.</Typography>
                                                    </Box>
                                                </StepLabel>
                                            </Step>
                                        </Stepper>
                                    </Box>
                                </Box>
                                {/* <Box sx={{ display: "flex", alignItems: "center", }}>
                                    <IconButton>
                                        <AccountCircleIcon sx={{ color: "#fff" }} />
                                    </IconButton>
                                    <Typography variant='label' sx={{ color: "#fff" }}>Ya soy  usuario, iniciar sesión.</Typography>
                                </Box> */}
                            </Box>
                        </Grid>
                        <Grid sx={{ height: "100%", overflowY: "auto" }} item xs={12} sm={8.5}>
                            <React.Fragment>
                                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%" }} p={4}>
                                    <Grid container>
                                        <Grid item xs={12} sm={2.5}>
                                            {/* <Button variant='text'>
                                                <KeyboardBackspaceIcon sx={{ mr: 0.5, fontSize: "20px" }} />
                                                <Typography sx={{ textTransform: "capitalize" }} variant='label'>Volver atrás</Typography>
                                            </Button> */}
                                        </Grid>
                                        <Grid item xs={12} sm={8.5}>
                                            <Box>
                                                <Typography sx={{ color: "#000", fontWeight: 400 }} variant='h5' >Enhorabuena, ya eres miembro de ZOA.
                                                    Accede a tu panel de administrador y empieza a añadir miembros a tu organización.</Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 6 }}>
                                        <Box sx={{ flex: '1 1 auto' }} />
                                        <BasicThemeButton
                                            title="Siguiente"
                                            onClick={() => navigate("/subscription")}
                                            textVariant={"h5"}
                                            style={{ px: 2, py: 1 }}
                                            icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                                <path d="M13.2686 4.16878C13.5686 3.86882 13.9755 3.70032 14.3998 3.70032C14.8241 3.70032 15.231 3.86882 15.531 4.16878L22.731 11.3688C23.031 11.6688 23.1995 12.0757 23.1995 12.5C23.1995 12.9242 23.031 13.3311 22.731 13.6312L15.531 20.8312C15.2292 21.1226 14.8251 21.2839 14.4056 21.2803C13.986 21.2766 13.5847 21.1083 13.2881 20.8117C12.9914 20.515 12.8232 20.1137 12.8195 19.6942C12.8159 19.2747 12.9772 18.8705 13.2686 18.5688L17.5998 14.1H2.3998C1.97546 14.1 1.56849 13.9314 1.26843 13.6313C0.968376 13.3313 0.799805 12.9243 0.799805 12.5C0.799805 12.0756 0.968376 11.6687 1.26843 11.3686C1.56849 11.0685 1.97546 10.9 2.3998 10.9H17.5998L13.2686 6.43118C12.9687 6.13113 12.8001 5.72424 12.8001 5.29998C12.8001 4.87571 12.9687 4.46882 13.2686 4.16878Z" fill="white" />
                                            </svg>}
                                        />
                                    </Box>
                                </Box>
                            </React.Fragment>
                        </Grid>
                    </Grid>
                </GradientCard>
            </Box>
        </MainLayout>
    )
}
