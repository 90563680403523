import { Box } from '@mui/material'
import React from 'react'

export default function GradientCard({ sx, children }) {
  return (
    <Box sx={{
      width: '100%',
      borderRadius: '24px',
      position: 'relative',
      background: "linear-gradient(180deg, #E4F8FD 0%, #E2F8F8 13.11%, #D6F8DB 100%)",
      ...sx
    }}>
      {children}
    </Box>
  )
}
