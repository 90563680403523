export const getCompanyData = async (data) => {
    try {
        const response = await fetch("https://europe-west3-zoa-suite.cloudfunctions.net/getUser", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const responseData = await response.json();

        return { status: "success", data: responseData };
    } catch (error) {
        return { status: "error", error: error.message };
    }
};

export const getUserPlan = async (data) => {
    try {
        const response = await fetch("https://europe-west3-zoa-suite.cloudfunctions.net/getSubscriptionDetails", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        });
        console.log("response: ", response);

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const responseData = await response.json();

        return { status: "success", data: responseData };
    } catch (error) {
        return { status: "error", error: error.message };
    }
};

export const updateData = async (data) => {
    return fetch("https://europe-west3-zoa-suite.cloudfunctions.net/updateUser", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
    })
        .then((res) => res.json())
        .then((data) => {
            return { status: "success", data: data }
        });
}

export const UserCheckMail = async (mail) => {
    return fetch("https://europe-west3-zoa-suite.cloudfunctions.net/check-user-new", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(mail),
    })
        .then((res) => res.json())
        .then((data) => data);
}

export const AddMoreUser = async (data) => {
    const response = await fetch("https://europe-west3-zoa-suite.cloudfunctions.net/addNewUser", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
    });
    const responseData = await response.json();
    if (response.status === 200) {
        return { status: "success", data: responseData };
    } else {
        return { status: "error", data: responseData };
    }
};

export const DeleteUser = async (data) => {
    const response = await fetch("https://europe-west3-zoa-suite.cloudfunctions.net/removeCompanyUser", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
    });
    const responseData = await response.json();
    if (response.status === 200) {
        return { status: "success", data: responseData };
    } else {
        return { status: "error", data: responseData };
    }
};
