import React, { useEffect, useState } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { getUserPlan } from '../../controller/userController'
import PageLoader from '../shared/loader'

export default function ManageSubscription(userId) {
    const [planData, setPlanData] = useState()
    const [loading, setLoading] = useState(false)


    const getPlan = async () => {
        setLoading(true)
        const data = {
            uid: userId.userId
        }
        const result = await getUserPlan(data)
        console.log("result: ", result);
        if (result.status === "success") {
            setLoading(false)
            setPlanData(result.data)
        }
    }

    useEffect(() => {
        getPlan()
    }, [])

    return (
        <>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexDirection: "column", minHeight: "100%", p: 4 }}>
                <Box sx={{ width: { sm: "60%", xs: "100%" } }}>
                    <Typography sx={{ fontSize: "26px", letterSpacing: "-1.6px", textAlign: "justify", fontWeight: 700 }} variant='h3'>Gestione su suscripción a ZOA.</Typography>
                    <Grid columnSpacing={2} sx={{ rowGap: 2, mt: 2 }} container>
                        <Grid item xs={12}>
                            {planData &&
                                <Box sx={{ display: "flex", alignItems: "center", columnGap: 2 }}>
                                    <Box>
                                        <Typography variant='subtitle1'>Su plan actual es</Typography>
                                        <Typography mt={1} sx={{ fontSize: "26px" }} variant='h3'>ESTÁNDAR</Typography>
                                        <Typography variant='h6'><Typography sx={{ color: "#0598b9", fontWeight: 700, fontSize: "20px" }} component={"span"}>{planData.totalUserInCompany}</Typography> usuario/s activo/s</Typography>
                                    </Box>
                                    <Box>
                                        <img src="/images/icon-precios-basic-2.png" alt="" />
                                    </Box>
                                </Box>
                            }
                        </Grid>
                        {/* <Grid item xs={12}>
                            <Typography variant='subtitle1'>¿Necesita más usuarios?</Typography>
                            <ThemeInput
                                value={"Ministerio de Monstruas"}
                                type={"text"}
                                sx={{ mt: 1 }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='subtitle1'>Confirme su contraseña para aceptar el cambio de plan.</Typography>
                            <ThemeInput
                                label="Dirección"
                                value={"Calle Juela, 16"}
                                type={"password"}
                                sx={{ mt: 1 }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography component={Box} variant='subtitle1'>Suscripción de newsletter activada.</Typography>
                            <Typography mt={1} component={Box} variant='label'>Actualmente está recibiendo las novedades de ZOA en su email, para dejar de recibirlas desactívelas.</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{ display: "flex", alignItems: "center", columnGap: 2 }}>
                                <ThemeSwitch />
                                <Typography variant='subtitle1'>Newsletter activada</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid> */}
                    </Grid>
                </Box>
                {/* <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%", mt: 6 }}>
                    <BasicThemeButton
                        title={"Guardar cambios"}
                        textVariant="h4"
                        icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                            <path d="M3.464 21.035C4.93 22.5 7.286 22.5 12 22.5C16.714 22.5 19.071 22.5 20.535 21.035C22 19.572 22 17.214 22 12.5C22 12.159 22 11.988 21.985 11.814C21.911 10.9963 21.5897 10.2206 21.064 9.59C20.9102 9.41529 20.749 9.24714 20.581 9.086L15.414 3.919C15.2527 3.75112 15.0846 3.58999 14.91 3.436C14.2793 2.91067 13.5035 2.58978 12.686 2.516C12.512 2.5 12.34 2.5 12 2.5C7.286 2.5 4.929 2.5 3.464 3.964C2 5.43 2 7.786 2 12.5C2 17.214 2 19.571 3.464 21.035Z" stroke="white" stroke-width="1.5" />
                            <path d="M17 22.5V21.5C17 19.614 17 18.672 16.414 18.086C15.828 17.5 14.886 17.5 13 17.5H11C9.114 17.5 8.172 17.5 7.586 18.086C7 18.672 7 19.614 7 21.5V22.5" stroke="white" stroke-width="1.5" />
                            <path d="M7 8.5H13" stroke="white" stroke-width="1.5" stroke-linecap="round" />
                        </svg>}
                        style={{
                            padding: "16px 24px",
                            textTransform: "capitalize",
                        }}
                    />
                </Box> */}
            </Box>
            {loading && <PageLoader />}
        </>
    )
}
