import { ThemeProvider, createTheme } from "@mui/material/styles";
import themeOptions from "./ThemeOption";
import typography from "./typography";
// import { cspacing } from "./spacing";
// import { useTheme } from "@emotion/react";

const ThemeComponent = (props) => {
    const { settings, children } = props;
    //   const theme = useTheme()

    const coreThemeConfig = themeOptions(settings);

    let theme = createTheme(coreThemeConfig);

    // console.log("theme: ", theme);
    
    theme = createTheme(theme, {
        // components: {
        //   MuiTextField: {
        //     styleOverrides: {
        //       root: {
        //         fieldset: {
        //           borderColor: "#017BFF",
        //         },
        //       },
        //     },
        //   },
        //   MuiInputBase: {
        //     styleOverrides: {
        //       root: {
        //         fieldset: {
        //           borderColor: "#017BFF",
        //         },
        //       },
        //     },
        //   },
        // },
        typography: { ...typography(theme) },
        // cspacing: { ...cspacing(theme) },
    });

    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default ThemeComponent;
