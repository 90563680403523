
export const UserLogin = async (data) => {
    try {
        const response = await fetch("https://europe-west3-zoa-suite.cloudfunctions.net/loginUser", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const responseData = await response.json();
        console.log("data: ", responseData);

        return { status: "success", data: responseData };
    } catch (error) {
        console.error("Error during fetch:", error);
        return { status: "error", error: error.message };
    }
};


export const AddUserData = async (data) => {
    console.log(data, 'auth')
    try {
        const response = await fetch("https://europe-west3-zoa-suite.cloudfunctions.net/createUser", {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const responseData = await response.json();
        localStorage.setItem("uid", responseData.user.uid)
        return { success: true, data: responseData };
    }
    catch (err) {
        console.error(err, 'error');
        return { success: false, err: err};
    }

}

export const paymentRequest = async (plan) => {
    return fetch("https://europe-west3-zoa-suite.cloudfunctions.net/stripe-payment-recurring", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(plan),
    })
        .then((res) => res.json())
        .then((data) => {
            console.log("data: ", data);
            return data
        });

}


export const SendEmailRequest = async (data) => {
    console.log(data, 'auth')
    try {
        const response = await fetch("https://europe-west3-zoa-suite.cloudfunctions.net/createUser", {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const responseData = await response.json();
        localStorage.setItem("uid", responseData.user.uid)
        return { success: true, data: responseData };
    }
    catch (err) {
        console.error(err, 'error');
        return { success: false, err: err};
    }

}

export const getApi = async () => {
    return fetch("https://api.ipify.org?format=json", {
        method: "GET",
        // headers: { "Content-Type": "application/json" },
        // body: JSON.stringify(data),
    })
        .then((res) => res.json())
        .then((data) => {
            return { status: "success", data: data }
        });
}
