import { Box, Typography } from '@mui/material'
import React from 'react'

export default function GoToZoa() {
    return (
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexDirection: "column", minHeight: "100%", p: 4 }}>
            <Box sx={{ width: { sm: "60%", xs: "100%" } }}>
                <Typography sx={{ fontSize: "26px", letterSpacing: "-1.6px", textAlign: "justify",fontWeight:700 }} variant='h3'>Su sesión en ZOA ERP, se ha abierto en una nueva pestaña.</Typography>
            </Box>
        </Box>
    )
}
