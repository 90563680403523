import React, { useState } from 'react'
import { Box, Button, Grid, Typography } from '@mui/material'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { atom, useRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import MainLayout from '../../../component/layout/mainLayout';
import GradientCard from '../../../component/shared/gradientCard';
import { ThemeInput } from '../../../component/shared/Input';
import BasicThemeButton from '../../../component/shared/button';
import PageLoader from '../../../component/shared/loader';
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import firebaseApp from '../../../controller/firebase';


const userIdState = atom({
    key: 'uid',
    default: localStorage.getItem('uid') || null,
});



export default function ForgetPassword() {
    const [loading, setLoading] = useState(false);
    const [userId, setUserId] = useRecoilState(userIdState);
    const navigate = useNavigate()
    const [email, setEmail] = useState("")
    const handlePassword = async () => {
        const auth = getAuth(firebaseApp);
        try {
           const result = await sendPasswordResetEmail(auth, email);
           console.log("result: ", result);
            navigate("/send-to-mail");
            console.log("Password reset email sent!");
        } catch (error) {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.error("Error sending password reset email:", errorCode, errorMessage);
        }
    };

    return (
        <>
            <MainLayout>
                <Box sx={{ py: "20px", height: "90vh" }}>
                    <GradientCard sx={{ height: "100%", overflow: "hidden" }}>
                        <Grid container height={"100%"}>
                            <Grid item xs={12} sm={3.8}>
                                <Box sx={{ width: "100%", height: "100%", background: "#0598B9", p: 4 }}>
                                    <Typography sx={{ fontSize: "26px", letterSpacing: "-1.6px", color: "white", fontWeight: 700 }} variant='h3'>Bienvenido a ZOA</Typography>
                                    <Typography sx={{ fontWeight: 500, color: "white" }} variant='label'>Estás a punto de adentrarte en la innovadora manera de administrar tus capacidades, recursos y entorno.</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={8.2}>
                                <Box sx={{ width: "100%", height: "100%", p: 4 }}>
                                    <Box
                                        sx={{
                                            width: "100%",
                                            height: "100%",
                                            position: "relative",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}>
                                        <Button sx={{ position: "absolute", top: 0, left: 0 }} onClick={() => navigate("/login")} variant='text'>
                                            <Box sx={{ display: "flex", alignItems: "center", columnGap: 1 }}>
                                                <KeyboardBackspaceIcon />
                                                <Typography variant='label'>Volver atrás</Typography>
                                            </Box>
                                        </Button>
                                        <Box sx={{ display: "flex", flexDirection: "column", rowGap: 2, width: "500px" }}>
                                            <Typography sx={{ fontSize: "26px", letterSpacing: "-1.6px", fontWeight: 700 }} variant='h3'>¿No puedes iniciar sesión?</Typography>
                                            <Typography onClick={() => navigate("/forget-password")} variant='label' sx={{ color: "#000", cursor: "pointer" }}>Enviaremos un enlace de recuperación a</Typography>
                                            <ThemeInput
                                                label="Nombre de usuario o email"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                type={"text"}
                                            />
                                            <Box sx={{ display: "flex", justifyContent: "center" }}>
                                                <BasicThemeButton
                                                    onClick={() => handlePassword()}
                                                    title={"Enviaremos un enlace de recuperación a"}
                                                    textVariant="h5"
                                                    icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                                        <path d="M13.2686 4.16878C13.5686 3.86882 13.9755 3.70032 14.3998 3.70032C14.8241 3.70032 15.231 3.86882 15.531 4.16878L22.731 11.3688C23.031 11.6688 23.1995 12.0757 23.1995 12.5C23.1995 12.9242 23.031 13.3311 22.731 13.6312L15.531 20.8312C15.2292 21.1226 14.8251 21.2839 14.4056 21.2803C13.986 21.2766 13.5847 21.1083 13.2881 20.8117C12.9914 20.515 12.8232 20.1137 12.8195 19.6942C12.8159 19.2747 12.9772 18.8705 13.2686 18.5688L17.5998 14.1H2.3998C1.97546 14.1 1.56849 13.9314 1.26843 13.6313C0.968376 13.3313 0.799805 12.9243 0.799805 12.5C0.799805 12.0756 0.968376 11.6687 1.26843 11.3686C1.56849 11.0685 1.97546 10.9 2.3998 10.9H17.5998L13.2686 6.43118C12.9687 6.13113 12.8001 5.72424 12.8001 5.29998C12.8001 4.87571 12.9687 4.46882 13.2686 4.16878Z" fill="white" />
                                                    </svg>}
                                                    style={{
                                                        padding: "16px 24px",
                                                        fontSize: "12px"
                                                    }}
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </GradientCard>
                </Box>
            </MainLayout>
            {loading && <PageLoader />}
        </>

    )
}
