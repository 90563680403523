
import { initializeApp } from 'firebase/app';

const firebaseConfig = {
    apiKey: "AIzaSyBkre9Htnmvd63pobVoPJ5sUf-GXYhyw9o",
    authDomain: "zoa-suite.firebaseapp.com",
    projectId: "zoa-suite",
    storageBucket: "zoa-suite.appspot.com",
    messagingSenderId: "673887944015",
    appId: "1:673887944015:web:412e07e9c1b1f880c3993a",
    measurementId: "G-D2QWV9JNPT"
};

const firebaseApp = initializeApp(firebaseConfig);

export default firebaseApp;