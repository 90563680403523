import Box from '@mui/material/Box'
import React from 'react'
import MainLayout from '../component/layout/mainLayout'
import HeroSection from '../component/home/heroSection'
import DetailSection from '../component/home/detailSection'
import AboutSection from '../component/home/aboutSection'
import MarkingSection from '../component/home/markingSection'
import PlanSection from '../component/home/planSection'
import FooterSection from '../component/home/footerSection'

export default function HomePage() {

    return (
        <>
            <MainLayout>
                <Box py={"20px"} component='section'>
                    <HeroSection />
                </Box>
                <Box py={{ sm: "50px", xs: "20px" }} component='section'>
                    <DetailSection />
                </Box>
                <Box py={{ sm: "150px", xs: "50px" }} component='section'>
                    <AboutSection />
                </Box>
                <Box py={{ sm: "50px", xs: "20px" }} component='section'>
                    <MarkingSection />
                </Box>
                <Box id="Precio" py={{ sm: "50px", xs: "20px" }} component='section'>
                    <PlanSection />
                </Box>
                <Box id="Ayuda" py={{ sm: "50px", xs: "20px" }} component='section'>
                    <FooterSection />
                </Box>
            </MainLayout>
        </>
    )
}
