import React, { useEffect, useState } from "react";
import { ThemeInput, ThemeSelect } from "../shared/Input";
import {
  Box,
  Button,
  Checkbox,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  MenuItem,
  Typography,
} from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import PrivacyPolicyModal from "../modal/privacyPolicy";
import countryData from "../country/countries.json";
console.log("countryData: ", countryData);

export default function UserInfo({
  data,
  setData,
  handleSelectChange,
  policyChecked,
  selectedCountry,
  setPhoneNumber,
  phoneNumber,
  isFocused,
  handlePrev,
  setConfirmPassword,
  confirmPassword,
  userId,
  setPolicyChecked,
}) {
  const [passwordError, setPasswordError] = useState({
    error: false,
    errorMessage: "",
  });
  const [showPassword, setShowPassword] = useState({
    main: false,
    confirm: false,
  });
  const [open, setOpen] = useState(false);

  const handlePasswordError = () => {
    if (!isFocused) return;
    if (!data.user.password) {
      setPasswordError({ error: true, errorMessage: "Se requiere contraseña" });
    } else if (data.user.password.length < 8) {
      setPasswordError({
        error: true,
        errorMessage: "Usa una contraseña con al menos 8 caracteres",
      });
    } else if (data.user.password !== confirmPassword) {
      setPasswordError({
        error: true,
        errorMessage: "Las contraseñas no coinciden",
      });
    } else if (
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]*/.test(data.user.password)
    ) {
      setPasswordError({
        error: true,
        errorMessage:
          "La contraseña debe tener números, símbolos, letras mayúsculas y minúsculas",
      });
    } else {
      setPasswordError({ error: false, errorMessage: "" });
    }
  };

  useEffect(() => {
    handlePasswordError();
  }, [isFocused, data.user.password, confirmPassword]);

  const handleClickShowPassword = () =>
    setShowPassword({
      confirm: showPassword.confirm,
      main: !showPassword.main,
    });

  const handleClickShowPasswordConfirm = () =>
    setShowPassword({
      confirm: !showPassword.confirm,
      main: showPassword.main,
    });

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  // useEffect(() => {
  //   handlePasswordError()
  // }, [data.user.password, isFocused])

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={2.5}>
          <Button onClick={handlePrev} variant="text">
            <KeyboardBackspaceIcon sx={{ mr: 0.5, fontSize: "20px" }} />
            <Typography sx={{ textTransform: "math-auto" }} variant="label">
              Volver atrás
            </Typography>
          </Button>
        </Grid>
        <Grid item xs={12} sm={7.5}>
          {data && (
            <Box>
              <Grid spacing={2} container>
                <Grid item xs={12} sm={6}>
                  <ThemeInput
                    label={"Nombre"}
                    error={isFocused && !data.user.userName}
                    helperText={
                      isFocused &&
                      !data.user.userName &&
                      "Se requiere el Nombre"
                    }
                    value={data.user.userName}
                    onChange={(e) =>
                      setData({
                        ...data,
                        user: { ...data.user, userName: e.target.value },
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ThemeInput
                    label={"Apellidos"}
                    value={data.user.userSurname}
                    error={isFocused && !data.user.userSurname}
                    helperText={
                      isFocused &&
                      !data.user.userSurname &&
                      "Se requieren los Apellidos"
                    }
                    onChange={(e) =>
                      setData({
                        ...data,
                        user: { ...data.user, userSurname: e.target.value },
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <ThemeInput
                    label={"Rol"}
                    value={data.user.rol}
                    error={isFocused && !data.user.rol}
                    helperText={
                      isFocused && !data.user.rol && "Se requiere el Rol"
                    }
                    onChange={(e) =>
                      setData({
                        ...data,
                        user: { ...data.user, rol: e.target.value },
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <ThemeInput
                    label={"Edad"}
                    value={data.user.age}
                    type={"number"}
                    error={isFocused && !data.user.age}
                    helperText={
                      isFocused && !data.user.age && "Se requiere la Edad"
                    }
                    onChange={(e) =>
                      setData({
                        ...data,
                        user: { ...data.user, age: e.target.value },
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <ThemeInput
                    label={"Email"}
                    value={data.user.mail}
                    error={isFocused && !data.user.mail}
                    helperText={
                      isFocused && !data.user.mail && "Se requiere el Email"
                    }
                    onChange={(e) =>
                      setData({
                        ...data,
                        user: { ...data.user, mail: e.target.value },
                      })
                    }
                    type={"email"}
                  />
                </Grid>
                <Grid item xs={4} sm={4}>
                  <ThemeSelect
                    label={"País"}
                    value={selectedCountry}
                    error={isFocused && !selectedCountry}
                    helperText={
                      isFocused && !selectedCountry && "Se requiere el País"
                    }
                    sx={{
                      ".MuiInputBase-input": {
                        paddingInline: "0px !impotent",
                      },
                    }}
                    onChange={handleSelectChange}
                    id="countryCodes"
                    name="countryCodes"
                  >
                    {countryData.countries.map((item, index) => (
                      <MenuItem key={index} value={item.dial_code}>
                        {item.name_es}
                      </MenuItem>
                    ))}
                  </ThemeSelect>
                </Grid>
                <Grid item xs={8} sm={8}>
                  <ThemeInput
                    label={"Teléfono"}
                    value={phoneNumber}
                    error={isFocused && !phoneNumber}
                    helperText={
                      isFocused && !phoneNumber && "Se requiere el Teléfono"
                    }
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    type={"text"}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {selectedCountry ? selectedCountry : ""}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                {!userId && (
                  <Grid item xs={12} sm={6}>
                    <ThemeInput
                      label={"Contraseña"}
                      value={data.user.password}
                      error={passwordError.error}
                      helperText={
                        passwordError.error && passwordError.errorMessage
                      }
                      onChange={(e) =>
                        setData({
                          ...data,
                          user: { ...data.user, password: e.target.value },
                        })
                      }
                      type={showPassword.main ? "text" : "password"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPassword.main ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                )}
                {!userId && (
                  <Grid item xs={12} sm={6}>
                    <ThemeInput
                      label={"Confirmar contraseña"}
                      value={confirmPassword}
                      error={passwordError.error}
                      helperText={
                        passwordError.error && passwordError.errorMessage
                      }
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      type={showPassword.confirm ? "text" : "password"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPasswordConfirm}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPassword.confirm ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                )}
              </Grid>
              {!userId && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: 1,
                    pt: 1,
                    width: "85%",
                  }}
                >
                  {/* <Typography sx={{ fontSize: "18px", fontWeight: 400, fontStyle: "italic", color: "#000" }} variant='label' >La contraseña debe ser de al menos 8 caracteres, que sean mayúsculas, minúsculas, números y símbolos.</Typography> */}
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Checkbox
                      onChange={(e) => setPolicyChecked(e.target.checked)}
                      checked={policyChecked}
                    />
                    <Typography component={"span"} variant="label">
                      Al registrarse, acepta las{" "}
                      <Link
                        onClick={handleOpen}
                        sx={{
                          color: "blue",
                          textDecoration: "none",
                          cursor: "pointer",
                        }}
                      >
                        Condiciones de uso
                      </Link>{" "}
                      y la{" "}
                      <Link
                        onClick={handleOpen}
                        sx={{
                          color: "blue",
                          textDecoration: "none",
                          cursor: "pointer",
                        }}
                      >
                        Política de privacidad y datos.
                      </Link>
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
          )}
        </Grid>
      </Grid>
      <PrivacyPolicyModal open={open} handleClose={handleClose} />
    </>
  );
}
