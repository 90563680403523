import React, { useState } from 'react'
import GradientCard from '../shared/gradientCard'
import { Box, Grid, Typography } from '@mui/material'
import ThemeSwitch from '../shared/switch'

const plan = [
    {
        plan: "BÁSICO",
        image: "/images/icon-precios-basic-1.png",
        detail: "Para tripulaciones de hasta",
        number: "5",
        cost: {
            year: { main: "29,80", discounted: "14,90" },
            month: { main: "41,80", discounted: "20,90" }
        },
        description: "por usuario al mes"
    },
    {
        plan: "ESTÁNDAR",
        image: "/images/icon-precios-basic-2.png",
        detail: "Para tripulaciones de hasta",
        number: "15",
        cost: {
            year: { main: "25,40", discounted: "12,70" },
            month: { main: "33,40", discounted: "16,70" }
        },
        description: "por usuario al mes"
    },
    {
        plan: "AVANZADO",
        image: "/images/icon-precios-basic-3.png",
        detail: "Para tripulaciones de hasta",
        number: "30",
        cost: {
            year: { main: "22,40", discounted: "11,20" },
            month: { main: "24,40", discounted: "14,20" }
        },
        description: "por usuario al mes"
    },
    {
        plan: "PRO",
        image: "/images/icon-precios-basic-4.png",
        detail: "Para toda la colonia",
        number: "+30",
        cost: {
            year: { main: "700", discounted: "350" },
            month: { main: "1000", discounted: "500" }
        },
        description: "€ por todos los usuarios al mes"
    },
]

export default function PlanSection() {
    const [checked, setChecked] = useState(false);
    console.log("checked: ", checked);

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    return (
        <>
            <GradientCard sx={{ px: { sm: 4, xs: 1 }, py: 2 }}>
                <Typography sx={{ textAlign: "center", position: "relative", zIndex: 2, color: "#4D4D4D", fontWeight: "700" }} variant='h2'>Prueba ZOA durante 15 días gratis</Typography>
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", columnGap: 1, my: 1, position: "relative", zIndex: 2 }}>
                    <Typography sx={{ color: "var(--Monochromatic-06, #999)" }} variant='label'>Anual</Typography>
                    <ThemeSwitch
                        checked={checked}
                        onChange={handleChange}
                    />
                    <Typography sx={{ color: "var(--Monochromatic-06, #999)" }} variant='label'>Mensual</Typography>
                </Box>
                <Grid container sx={{ position: "relative" }} spacing={{ sm: 4, xs: 2 }}>
                    {plan.map((item, index) =>
                        <Grid sx={{ position: "relative", zIndex: 2, my: { sm: 4, xs: 0 } }} key={index} item xs={12} md={3}>
                            <Box
                                onClick={() => console.log(item.plan)}
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    height: "100%",
                                    padding: "24px 0px 0px 0px",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    gap: "13px",
                                    alignSelf: "stretch",
                                    backgroundColor: "#fff",
                                    borderRadius: "24px",
                                    overflow: "hidden",
                                    cursor: "pointer",
                                }}>
                                <Box sx={{
                                    width: "70%",
                                    textAlign: "center",
                                    borderRadius: "24px",
                                    background: "var(--Texto-menu-app-lv1, #0598B9)",
                                    gap: "8px",
                                    color: "#fff",
                                    p: 1
                                }}>
                                    <Typography variant='h4'>PLAN</Typography>
                                    <Typography variant='h4'>{item.plan}</Typography>
                                </Box>
                                <Box sx={{ display: "flex", rowGap: 1, flexDirection: "column", alignItems: "center" }}>
                                    <Box sx={{ height: "100px" }}>
                                        <img src={item.image} alt='' height={"100%"} />
                                    </Box>
                                    <Typography sx={{ color: "var(--Monochromatic-06, #999)" }} variant='label'>{item.detail}</Typography>
                                </Box>
                                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                    <Typography sx={{ color: "var(--Monochromatic-06, #999)", fontWeight: 600 }} variant='h1'>{item.number}</Typography>
                                    <Typography sx={{ color: "var(--Monochromatic-06, #999)" }} variant='h3'>Usuarios</Typography>
                                </Box>
                                <Box sx={{
                                    width: "100%",
                                    background: checked ? "#04D94F" : "#2D5BFF",
                                    position: "relative",
                                    color: "#fff",
                                    px: 1,
                                    py: 2
                                }}>
                                    <Typography component={Box} sx={{ position: "absolute", right: 5, top: 5, textDecoration: "line-through", color: "red" }} variant='label'><Box sx={{ color: "#fff" }} component={"span"}>{checked ? item.cost.month.main : item.cost.year.main} €</Box></Typography>
                                    <Typography component={Box} sx={{ textAlign: "center" }} variant='h2'>{checked ? item.cost.month.discounted : item.cost.year.discounted} €</Typography>
                                    <Typography component={Box} sx={{ textAlign: "center", fontWeight: 500 }} variant='label'>€ {item.description}</Typography>
                                </Box>
                            </Box>
                        </Grid>
                    )}
                    <Box component={"img"} sx={{ position: "absolute", top: -10, right: { sm: -30, xs: 0 }, width: { xs: "30%", sm: "auto" } }} src='/images/vector/vector32.svg' alt='' />
                    <Box component={"img"} sx={{ position: "absolute", bottom: -10, left: 0, right: 110, mx: "auto", width: { xs: "30%", sm: "auto" } }} src='/images/vector/vector33.svg' alt='' />
                    <Box component={"img"} sx={{ position: "absolute", top: -30, left: { sm: 35, xs: 0 }, width: { xs: "30%", sm: "auto" } }} src='/images/vector/vector34.svg' alt='' />
                </Grid>
            </GradientCard >
        </>
    )
}
