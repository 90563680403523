import styled from '@emotion/styled';
import { TextField } from '@mui/material'
import React from 'react'

const RedditTextField = styled((props) => (
    <TextField InputProps={{
        disableUnderline: true,
    }} {...props} />
))(({ theme }) => ({
    '& .MuiFilledInput-root': {
        overflow: 'hidden',
        borderRadius: "16px",
        fontWeight: 500,
        backgroundColor: theme.palette.mode === 'light' ? '#FFF' : '#1A2027',
        border: '1px solid',
        borderColor: theme.palette.mode === 'light' ? '#000' : '#2D3843',
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
        '&:hover': {
            backgroundColor: '#fff',
        },
        '&:before': {
            borderBottom: 'none',
        },
        '&:hover:before': {
            borderBottom: 'none',
        },
        '&:after': {
            borderBottom: 'none',
        },
        '&.Mui-focused': {
            backgroundColor: '#fff',
            borderColor: "theme.palette.primary.main",
        },
    },
    '& input:-webkit-autofill': {
        WebkitBoxShadow: '0 0 0 100px #fff inset',
    },
    '& .MuiInputLabel-root': {
        marginLeft: 18,
        marginTop: 6,
        color: "#808080",
        fontWeight: 400
    },
    '& .MuiInputBase-input': {
        paddingInline: 18
    },
    // '& .MuiFormHelperText-root': {
    //     position: "absolute",
    //     bottom: -16
    // }
}));

const ThemeInput = ({ type, sx, value, onChange, label, placeholder, InputProps, error, helperText }) => {
    return (
        <RedditTextField
            fullWidth
            size="small"
            label={label}
            placeholder={placeholder}
            variant="filled"
            sx={{ ...sx }}
            type={type}
            InputProps={InputProps}
            error={error}
            helperText={helperText}
            value={value}
            onChange={onChange}
        />
    )
}

const ThemeSelect = ({ type, sx, error, helperText, value, onChange, label, placeholder, children }) => {
    return (
        <RedditTextField
            select
            fullWidth
            size="small"
            label={label}
            error={error}
            helperText={helperText}
            placeholder={placeholder}
            variant="filled"
            sx={{ ...sx }}
            type={type}
            value={value}
            onChange={onChange}
        >
            {children}
        </RedditTextField>
    )
}

export { ThemeInput, ThemeSelect };
